import { gql } from "@apollo/client";

const ADD_SENTENCE = gql`
mutation ADD_SENTENCE($basic_idea: BasicIdeaInput,
        $basic_idea_prime: BasicIdeaInput,
        $continuation: ContinuationInput,
        $terminus: TerminusInput,
        $winding_down_technique: WindingDownTechniqueInput
){
    addSentence(basic_idea: $basic_idea,
        basic_idea_prime: $basic_idea_prime,
        continuation: $continuation,
        terminus: $terminus,
        winding_down_technique: $winding_down_technique){
            id
        }
}
`

const UPDATE_SENTECE = gql`
mutation UPDATE_SENTECE($basic_idea: BasicIdeaInput,
        $basic_idea_prime: BasicIdeaInput,
        $continuation: ContinuationInput,
        $terminus: TerminusInput,
        $winding_down_technique: WindingDownTechniqueInput
){
    updateSentence(basic_idea: $basic_idea,
        basic_idea_prime: $basic_idea_prime,
        continuation: $continuation,
        terminus: $terminus,
        winding_down_technique: $winding_down_technique){
            id
        }
}
`
const DELETE_SENTENCE = gql`
mutation DELETE_SENTENCE($id: ID!){
    deleteSentence(id: $id){
        id
    }
}
`

export {ADD_SENTENCE, UPDATE_SENTECE, DELETE_SENTENCE}