import { gql } from "@apollo/client";

const ADD_TERNARY = gql`
mutation ADD_TERNARY($basic_idea: BasicIdeaInput,
                $basic_ternary: ID,
                $compound: ID,
                $initiating: ID,
                $presentation: ID,
                $continuation: ContinuationInput,
                $recapitulatory: RecapitulatoryInput,
                $terminus: TerminusInput,
                $winding_down_technique: WindingDownTechniqueInput
                ){
                    addTernary(basic_idea: $basic_idea,
                        basic_ternary: $basic_ternary,
                        compound: $compound,
                        initiating: $initiating,
                        presentation: $presentation,
                        continuation: $continuation,
                        recapitulatory: $recapitulatory,
                        terminus: $terminus,
                        winding_down_technique: $winding_down_technique){
                            id
                        }
                }`

const UPDATE_TERNARY = gql`
mutation UPDATE_TERNARY($id: ID!
                $basic_idea: BasicIdeaInput,
                $basic_ternary: ID,
                $compound: ID,
                $initiating: ID,
                $presentation: ID,
                $continuation: ContinuationInput,
                $recapitulatory: RecapitulatoryInput,
                $terminus: TerminusInput,
                $winding_down_technique: WindingDownTechniqueInput
                ){
                    updateTernary(id: $id,
                        basic_idea: $basic_idea,
                        basic_ternary: $basic_ternary,
                        compound: $compound,
                        initiating: $initiating,
                        presentation: $presentation,
                        continuation: $continuation,
                        recapitulatory: $recapitulatory,
                        terminus: $terminus,
                        winding_down_technique: $winding_down_technique){
                            id
                        }
                }`

const DELETE_TERNARY = gql `
mutation DELETE_TERNARY ($id: ID!){
    deleteTernary(id: $id){
        id
    }
}`

export {ADD_TERNARY, UPDATE_TERNARY, DELETE_TERNARY}