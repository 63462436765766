import { gql } from "@apollo/client";

export const ADD_DEVELOPMENT = gql`
mutation addDevelopment(
                $central_pitch_class: String,
                $measures: MeasuresInput,
                $pitch_class_collection: String,
                $summary: String)
                {
                addDevelopment(central_pitch_class: $central_pitch_class,
                    measures: $measures,
                    pitch_class_collection: $pitch_class_collection
                    summary: $summary){
                    id
                    central_pitch_class
                    measures{start, end}
                    pitch_class_collection
                    summary
                }
                }
`