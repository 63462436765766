import { gql } from "@apollo/client";

const ADD_INTRODUCTION_TWO = gql`
  mutation addIntroductionTwo(
    $organized_theme_type: Boolean,
    $basic: ID,
    $sentence: ID,
    $period: ID,
    $hybrid: ID,
    $ternary: ID,
    $other: ID,
    $summary: String
  ) {
    addIntroductionTwo(
      organized_theme_type: $organized_theme_type,
      basic: $basic,
      sentence: $sentence,
      period: $period,
      hybrid: $hybrid,
      ternary: $ternary,
      other: $other,
      summary: $summary
    ) {
      id
      organized_theme_type
      basic {
        id
      }
      sentence {
        id
      }
      period {
        id
      }
      hybrid {
        id
      }
      ternary {
        id
      }
      other {
        id
      }
      summary
    }
  }
`;

const UPDATE_INTRODUCTION_TWO = gql`
  mutation updateIntroductionTwo(
    $id: ID!,
    $organized_theme_type: Boolean,
    $basic: ID,
    $sentence: ID,
    $period: ID,
    $hybrid: ID,
    $ternary: ID,
    $other: ID,
    $summary: String
  ) {
    updateIntroductionTwo(
      id: $id,
      organized_theme_type: $organized_theme_type,
      basic: $basic,
      sentence: $sentence,
      period: $period,
      hybrid: $hybrid,
      ternary: $ternary,
      other: $other,
      summary: $summary
    ) {
      id
      organized_theme_type
      basic {
        id
      }
      sentence {
        id
      }
      period {
        id
      }
      hybrid {
        id
      }
      ternary {
        id
      }
      other {
        id
      }
      summary
    }
  }
`;

export { ADD_INTRODUCTION_TWO, UPDATE_INTRODUCTION_TWO };
