import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { ApolloProvider, ApolloClient, InMemoryCache, split, HttpLink } from '@apollo/client'
import {GraphQLWsLink} from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { getMainDefinition } from '@apollo/client/utilities';

import Home from './pages/Homepage'
import Dashboard from './pages/Dashboard'
import Login from './pages/Login'
import Users from './pages/Users'
import PieceDashboard from './pages/PieceDashboard'
import PieceForm from './pages/PieceForm'

const cache = new InMemoryCache({
  typePolicies: {
    Query:{
      fields:{
        users:{
          merge(existing, incoming){
            return incoming;
          }
        },
        pieces:{
          merge(existing, incoming){
            return incoming;
          }
        },
        movements:{
          merge(existing, incoming){
            return incoming;
          }
        },
      }
    }
  }
});



const httpLink = new HttpLink({
  uri: 'https://codetta.ca:400/graphql'
  //'http://138.197.168.160:4000/graphql'
  //'http://localhost:4000/graphql'
  //'https://codetta-back-end.vercel.app/graphql'
});

const wsLink = new GraphQLWsLink(createClient({
  url:'wss://codetta.ca:400/graphql'
  //'ws://138.197.168.160}:5000/graphql'
  //'ws://localhost:5000/graphql',
   //'ws://codetta-back-end.vercel.app/graphql'
  /*  connectionParams: {
    authToken: user.authToken,
  }, */
}));

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink,
);



const client = new ApolloClient({
  //uri: 'http://localhost:4000/graphql',
  link: splitLink,
  cache
})

function App() {
  return (
    <>
     <ApolloProvider client={client}>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/Login' element={<Login />} />
          <Route path='/Dashboard' element={<Dashboard />} />
          <Route path='/Users' element={<Users />} />
          <Route path='/Files' element={<PieceDashboard/>} />
          <Route path='/Forms/:id' element={<PieceForm/>} />
        </Routes>
      </BrowserRouter>
      </ApolloProvider>
    </>
  )
}

export default App