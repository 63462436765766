import Slide from "@mui/material/Slide";

function Other({other, intro}) {
    return (
        <>
            {/*<Slide direction="up" in={other && intro} mountOnEnter unmountOnExit>
                <div className="card my-5" >
                    <div className="card-body">
                        <h5 className="card-title ">Other</h5>
                    </div>
                </div>
    </Slide>*/}
        </>
    )
}

export default Other