import React, { useState } from 'react';
import Slide from '@mui/material/Slide';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem'; 

function Ternary({ ternary, intro }) {
    const [recapitulatorySection, setRecapitulatorySection] = useState('Recapitulatory A section'); 
    return (
    <Slide direction="up" in={ternary && intro} mountOnEnter unmountOnExit>
      <div className="card my-5">
        <div className="card-body">
          <h5 className="card-title">Ternary Type</h5>
          <style>
            {`
            .small-input {
                width: 50px; 
            }
            .form-section {
                display: flex;
                align-items: center;
                background-color: #f8f8f8;
                padding: 10px;
                width: 100%;
            }
            .measures-container {
                margin-right: 10px;
            }
            .bigGap {
                margin-bottom: 60px; 
            }
            .smallGap{
                margin-bottom: 10px;
            }
            `}
          </style>
          <div className="d-flex flex-column">
            <Grid container direction="row" alignItems="center" className="form-section smallGap">
                <Typography><b>A</b> Basic Idea</Typography>
                <div className="d-flex align-items-center ms-auto">
                    <div className="measures-container">
                        <Typography className="m-0 me-2">measures</Typography>
                    </div>
                    <TextField type="text" variant="outlined" size="small" className="me-2 small-input" />
                    <Typography className="m-0 me-2">to</Typography>
                    <TextField type="text" variant="outlined" size="small" className="small-input" />
                </div>
            </Grid>

            <Grid container direction="row" alignItems="center" className="form-section smallGap">
                <Typography><b>B</b> Section Continuation Idea</Typography>
                <div className="d-flex align-items-center ms-auto">
                    <div className="measures-container">
                        <Typography className="m-0 me-2">measures</Typography>
                    </div>
                    <TextField type="text" variant="outlined" size="small" className="me-2 small-input" />
                    <Typography className="m-0 me-2">to</Typography>
                    <TextField type="text" variant="outlined" size="small" className="small-input" />
                </div>
            </Grid>
            
            <Grid container direction="row" alignItems="center" className="form-section smallGap">
                <Select
                    value={recapitulatorySection}
                    onChange={(e) => setRecapitulatorySection(e.target.value)}
                    variant="outlined"
                    size="small"
                >
                    <MenuItem value="Recapitulatory A section">Recapitulatory <b>A</b> section</MenuItem>
                    <MenuItem value="essentiallyUnaltered">Essenially Altered</MenuItem>
                    <MenuItem value="altered">Altered</MenuItem>
                </Select>
                <div className="d-flex align-items-center ms-auto">
                    <div className="measures-container">
                        <Typography className="m-0 me-2">measures</Typography>
                    </div>
                    <TextField type="text" variant="outlined" size="small" className="me-2 small-input" />
                    <Typography className="m-0 me-2">to</Typography>
                    <TextField type="text" variant="outlined" size="small" className="small-input" />
                </div>
            </Grid>
          </div>
        </div>
      </div>
    </Slide>
  );
}

export default Ternary;