import { Link, useParams } from 'react-router-dom'
import logo from '../assets/logo.png';
import { useQuery, useMutation, useSubscription } from '@apollo/client'
import { GET_PIECES, GET_PIECE } from '../queries/pieceQueries';
import { PIECE_UPDATED_SUBSCRIPTION } from '../subscriptions/pieceSubscriptions.js';
import DashboardNavbar from '../components/DashboardNavbar';
import PieceNavbar from '../components/PieceNavbar';
import PieceSideNavigation from '../components/PieceSideNavigation';
import PieceOverview from '../components/PieceOverview';
import Movements from '../components/Movements';
import Codettas from '../components/Codettas';
import Development from '../components/Development';
import Recapitulation from '../components/Recapitulation';
import MomentOfFormalRupture from '../components/MomentOfFormalRupture';
import MovementTabs from '../components/MovementTabs';
import Coda from '../components/Coda';
import '../assets/app.css';
import { GET_PIECEOVERVIEW } from '../queries/pieceOverviewQueries';
import { GET_MOFR } from '../queries/mofrqueries';
import { useState, useEffect } from 'react';
import { GET_MOVEMENT } from "../queries/movementQueries";
import { UPDATE_PIECEOVERVIEW } from "../mutations/pieceOverviewMutations"
import { UPDATE_PIECE } from '../mutations/pieceMutations';
import LinearProgress from '@mui/material/LinearProgress';
import { ADD_MOVEMENT } from '../mutations/movementMutations';

function PieceForm() {
  const { id } = useParams();
  const [addMovement] = useMutation(ADD_MOVEMENT);
  const [updatePiece] = useMutation(UPDATE_PIECE);
  const [movementTitle, setMovementTitle] = useState("");
  const[movementIds, setMovementIds] = useState([]);
  //get the piece by the id passed in the url

  //piece componenets initialized with the piece
  var mofrID;
  var overviewID;
  var codaID;
  var developmentID;
  var movements = [];

 
  const pieceQueries = useQuery(GET_PIECE, { variables: { id: { id }.id } });
  useEffect(()=>{
    var id = [];
    if (!pieceQueries.loading){
      pieceQueries.data.piece.movements.map((movement)=>{
        id.push(movement.id);
      })
      setMovementIds(id);
    }
  }, [pieceQueries.data])


 // console.log('movement ids: '+ movementIds)
  const movementQueries = useQuery(GET_MOVEMENT ,{variables: {id: movementIds}});
 // console.log(movementQueries.data);


  if (!pieceQueries.loading) {
    mofrID = pieceQueries.data.piece.moment_of_formal_rupture.id;
    overviewID = pieceQueries.data.piece.piece_overview.id
    codaID = pieceQueries.data.piece.coda.id
    developmentID = pieceQueries.data.piece.development.id
    movements = pieceQueries.data.piece.movements
    /*console.log('mofr id: ' + mofrID
      + '\noverview id: ' + overviewID
      + '\ncoda id: ' + codaID
      + '\ndevelopment id: ' + developmentID
      + '\nmovements: ' + JSON.stringify(movements))*/
  }



  //needs to be fixed
  const [updateOverview] = useMutation(UPDATE_PIECEOVERVIEW);

  //we use the loading effect to set the states of the use state variables once the data is actually loaded 
  //set values once the promises from the GET Queries have been resolved 
  //he useEffect has a dependency array [overviewQueries.data], which means it will only run when overviewQueries.data changes. This ensures that the state is updated whenever the query data changes.




  //update user input functions



  //check for loading and error state 

  if (pieceQueries.error) {
    //show custom error
    return "oh no an oopsie happened 👉👈"
  }

  const handleAddMovement = async (event) => {
    event.preventDefault();
    const movementResult = await addMovement({
      variables: { title: movementTitle },
      update(cache, { data: { addMovement } }) {
       // console.log("cache: " + cache.readQuery({ query: GET_MOVEMENT, variables: {id: movementIds} }))
        const { movements } = cache.readQuery({ query: GET_MOVEMENT, variables: {id: movementIds} });
        cache.writeQuery({ 
          query: GET_MOVEMENT,
          variables: {id: movementIds},
          data:{
            movements: [...movements, addMovement]
          }
        }); 
      }
    });
    setMovementTitle("");
    const movement = movementResult.data.addMovement.id
    await updatePiece({ variables: { id: { id }.id, movements: [movement] } })
  }

  return (
    <>
      <div className="container-fluid p-0 formBody">
        <div className='container-fluid pnav'>
          <div className='container'>
            {/*when form is loading*/
              pieceQueries.loading && (
                <>
                  <nav className="navbar navbar-dark navbar-expand-lg">
                    <div className="container-fluid">
                      <a className="navbar-brand" href="/Files">
                        <img src={logo} alt="" width="24" height="24" className="d-inline-block align-text-top mx-2" ></img>
                        Codetta
                      </a>
                      <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                      </button>
                      <div className="collapse navbar-collapse" id="navbarText">
                        <ul className="navbar-nav mx-auto mb-2 mb-lg-0 flex-column">
                          <div className="row">
                            <form>
                              <input className='titleInput'></input>
                            </form>
                          </div>
                        </ul>
                        <span className="navbar-text nav-item dropdown mt-2">
                          <a className="nav-link dropdown-toggle" href="/Homepage" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            User's First and Last
                          </a>
                          <p>user@gmail.com</p>
                          <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                            <li><a className="dropdown-item" href="/Homepage">Action</a></li>
                            <li><a className="dropdown-item" href="/Homepage">Another action</a></li>
                            <li><a className="dropdown-item" href="/Homepage">Something else here</a></li>
                          </ul>
                        </span>
                      </div>
                    </div>
                  </nav>
                </>
              )}
            {!pieceQueries.loading && (<PieceNavbar piece={pieceQueries.data.piece} />)}
          </div>
        </div>
        {pieceQueries.loading && (<LinearProgress />)}
        <div className='container'>
          <div className='row'>
            <div className="col-md-2 verticalNav">
              <PieceSideNavigation />
            </div>
            <div className="col-md-8 py-5 px-5 mainForm" data-bs-spy="scroll" >
              {!pieceQueries.loading &&
                (<>
                  <PieceOverview id={overviewID} />
                  <MovementTabs movements={movements} />
                  <Codettas />
                  <MomentOfFormalRupture />
                  <Development />
                  <Recapitulation />
                  <Coda id={codaID} />
                </>)
              }

            </div>
            <div className="col-md-2 my-5">
              <div className='row'>
                <div className="col-md ">
                  <button className="btn btn-danger w-100">Delete</button>
                </div>
                <div className="col-md ">
                  <button className="btn btn-primary w-100">Save</button>
                </div>
              </div>
              <div className='card my-3 w-100'>
                <div className='card-body '>
                  <p className="fs-6 text-muted">Add new movement</p>
                  <form onSubmit={handleAddMovement}>
                    <textarea className="form-control mb-3" placeholder='Title' value={movementTitle} onChange={(e) => { setMovementTitle(e.target.value) }}></textarea>
                    <button className="btn btn-primary w-100" type="submit">Add</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PieceForm