import { gql } from "@apollo/client";

const ADD_SUBORDINATE_THEME_ONE = gql`
  mutation ADD_SUBORDINATE_THEME_ONE(
    $pitch_class_collection: String,
    $central_pitch_class: String,
    $organized_theme_type: Boolean,
    $basic: ID,
    $sentence: ID,
    $period: ID,
    $hybrid: ID,
    $ternary: ID,
    $other: ID,
    $summary: String
  ){
    addSubordinateThemeOne(
      pitch_class_collection: $pitch_class_collection,
      central_pitch_class: $central_pitch_class,
      organized_theme_type: $organized_theme_type,
      basic: $basic,
      sentence: $sentence,
      period: $period,
      hybrid: $hybrid,
      ternary: $ternary,
      other: $other,
      summary: $summary
    ) {
      id
    }
  }
`;

const UPDATE_SUBORDINATE_THEME_ONE = gql`
  mutation UPDATE_SUBORDINATE_THEME_ONE(
    $id: ID!,
    $pitch_class_collection: String,
    $central_pitch_class: String,
    $organized_theme_type: Boolean,
    $basic: ID,
    $sentence: ID,
    $period: ID,
    $hybrid: ID,
    $ternary: ID,
    $other: ID,
    $summary: String
  ){
    updateSubordinateThemeOne(
      id: $id,
      pitch_class_collection: $pitch_class_collection,
      central_pitch_class: $central_pitch_class,
      organized_theme_type: $organized_theme_type,
      basic: $basic,
      sentence: $sentence,
      period: $period,
      hybrid: $hybrid,
      ternary: $ternary,
      other: $other,
      summary: $summary
    ) {
      id
    }
  }
`;

const DELETE_SUBORDINATE_THEME_ONE = gql`
  mutation DELETE_SUBORDINATE_THEME_ONE($id: ID!){
    deleteSubordinateThemeOne(id: $id){
      id
    }
  }
`;

export { ADD_SUBORDINATE_THEME_ONE, UPDATE_SUBORDINATE_THEME_ONE, DELETE_SUBORDINATE_THEME_ONE }
