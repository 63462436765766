var previousId =[];
export function Select(id) {
    previousId.push(id);
    var prev = document.getElementById(previousId[0]);
    prev.className = "page-item append-link";
    var p = document.getElementById(id);
    p.className = "page-item append-link active";
    if(previousId.length >= 2) previousId.shift();
}

export function Search(input){
    var b = document.getElementById("searchQuery");
    b.style = "display: contents";
    var p = document.getElementById("dataBody");
    p.style = "display:none";
    var pg = document.getElementById("pagination");
    pg.style = "visibility: hidden";
    if(b.textContent.trim() ==="" || input=="") {
        p.style = "display: contents"
        b.style = "display: none";
        pg.style = "visibility: visible";
    };

}

