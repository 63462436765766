import { Slide } from "@mui/material"
import SectionEnable from "./SectionEnable"
import ThemeType from "./dropDownComponents/ThemeType"
import Summary from "./Summary"
import { useState, useEffect } from "react"
import { useMutation } from '@apollo/client';
import { ADD_BASIC } from '../mutations/basicMutations';
import { ADD_SENTENCE } from '../mutations/sentenceMutations';
import { ADD_PERIOD } from '../mutations/periodMutations';
import { ADD_HYBRID } from '../mutations/hybridMutations';
import { ADD_TERNARY } from '../mutations/ternaryMutations';
import { UPDATE_TRANSITION_ONE } from "../mutations/transitionOneMutations"

function TransitionOne({ transitionOne, transOne, handleTransitionOne }) {
    const [themeType, setThemeType] = useState(transOne === null ? false : (transOne.organized_theme_type));
    const [updateTransOne] = useMutation(UPDATE_TRANSITION_ONE);

    const [summary, setSummary] = useState(!transOne ? "" : transOne.summary)
    const [basic, setBasic] = useState(!transOne || !transOne.basic ? null : transOne.basic.id);
    const [sentence, setSentence] = useState(!transOne || !transOne.sentence ? null : transOne.sentence.id);
    const [period, setPeriod] = useState(!transOne || !transOne.period ? null : transOne.period.id);
    const [hybrid, setHybrid] = useState(!transOne || !transOne.hybrid ? null : transOne.hybrid.id);
    const [ternary, setTernary] = useState(!transOne || !transOne.ternary ? null : transOne.ternary.id);

    const [addBasic] = useMutation(ADD_BASIC);
    const [addSentence] = useMutation(ADD_SENTENCE);
    const [addPeriod] = useMutation(ADD_PERIOD);
    const [addHybrid] = useMutation(ADD_HYBRID);
    const [addTernary] = useMutation(ADD_TERNARY);


    const handleIntroUpdate =  () => {
        updateTransOne({ variables: { id: transOne.id, organized_theme_type: themeType, summary, basic, sentence, period, hybrid, ternary } })
        //console.log('start: ' + start + ' end: ' + end)
    }
    //console.log('is theme enabled: ' + themeType);

    useEffect(() => {
        if (transOne) {
            handleIntroUpdate();
        }
    }, [themeType, summary, basic, sentence, period, hybrid, ternary]);

   
    const handleThemeEnable = () => {
        setThemeType(!themeType);

    }
    const handleBasic = async () => {
        var basicResult = await addBasic();
        setBasic(basicResult.data.addBasic.id);
    };
    const handleSentence = async () => {
        var sentenceResult = await addSentence();
        setSentence(sentenceResult.data.addSentence.id)
    }
    const handlePeriod = async () => {
        var periodResult = await addPeriod();
        setPeriod(periodResult.data.addPeriod.id);
    }
    const handleHybrid = async () => {
        var hybridResult = await addHybrid();
        setHybrid(hybridResult.data.addHybrid.id);
    }
    const handleTernary = async () => {
        var ternaryResult = await addTernary();
        setTernary(ternaryResult.data.addTernary.id);
    }
    
    return (
        <>
            <SectionEnable title={'First Transition'} show={true} checked={transitionOne} handleChange={handleTransitionOne} />
            <ThemeType show={transitionOne} checked={themeType} handleChange={handleThemeEnable} component={transOne}
                handleBasic={handleBasic} basicId={basic}
                handleSentence={handleSentence} sentenceId={sentence}
                handlePeriod={handlePeriod} periodId={period}
                handleHybrid={handleHybrid} hybridId={hybrid}
                handleTernary={handleTernary} ternaryId={ternary} />

            <Slide direction="up" in={transitionOne} mountOnEnter unmountOnExit>
                <div className="card my-5">
                    <div className="card-body">
                        <h5 className="card-title ">Pitch Class Collection</h5>
                        <div className="input-group input-group-lg my-4">
                            <input type="text" className="form-control" placeholder="C" aria-describedby="inputGroup-sizing-lg"></input>
                        </div>
                        <h5 className="card-title mt-5">Central Pitch Class</h5>
                        <div className="input-group input-group-lg my-4">
                            <input type="text" className="form-control" placeholder="C - D♭ - D♯/E♭ - E - F# - G - A - B♭" aria-label="Sizing example input"
                                aria-describedby="inputGroup-sizing-lg"></input>
                        </div>
                    </div>
                </div>
            </Slide>
        </>
    )
}

export default TransitionOne