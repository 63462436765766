import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { useState } from 'react';


const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));


const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .0)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));
function PieceSideNavigation() {

    const [expanded, setExpanded] = useState('panel1');

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    return (
        <>
            <nav id="sideNavigation">
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <a className="nav-link active mx-4 nav-text" aria-current="page" href="#">Overview</a>
                <div>

                    <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                            Movement
                        </AccordionSummary>
                        <AccordionDetails>
                            <a className="nav-link active mx-4 nav-text" aria-current="page" href="#intro">Introduction One</a>
                            <a className="nav-link mx-4 nav-text" href="#lsintro">Large-Scale Intro</a>
                            <a className="nav-link mx-4 nav-text" href="#">Introduction Two</a>
                        </AccordionDetails>
                    </Accordion>
                    {/*<button className="nav-link disabled" href="#" tabIndex="-1" aria-disabled="true">
                        <span className="material-symbols-outlined">
                            chevron_right
    </span>Disabled</button>*/}
                </div>
                <a className="nav-link mx-4 nav-text" href="#">Codettas</a>
                <a className="nav-link mx-4 nav-text" href="#">Moment of Formal Rupture</a>
                <a className="nav-link mx-4 nav-text" href="#">Development</a>
                <a className="nav-link mx-4 nav-text" href="#">Recapitulation</a>
                <a className="nav-link mx-4 nav-text" href="#">Coda</a>





            </nav>

        </>
    )
}

export default PieceSideNavigation