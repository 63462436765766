import '../assets/app.css'
import logo from '../assets/logo.png';
import { useState, useEffect } from 'react';
import axios from 'axios';


function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    //post to backend auth api using axios and FormData API
    async function authenticateUser(event){
        event.preventDefault();
        const data ={
            email: email,
            password: password
        }

        var res = await axios.post('https://codetta-back-end.vercel.app/login', data)
        //http://localhost:4000/login
        console.log(res.data);
        if(res.data.user){
            localStorage.setItem('token', res.data.user);
            window.location.href='/Dashboard'
        }
        else{
            alert('log in credentials failed');
        }
    }

    async function promisedToken(){
        var res = await axios.get('https://codetta-back-end.vercel.app/auth', {'headers': {'x-access-token': localStorage.getItem("token")}})
        //http://localhost:4000/auth
        console.log(res);
    }
    useEffect(() =>{
       promisedToken(); 
    }, [])
    return (
        <>
            <div className="text-center form-body">
                <form className="form-signin" onSubmit={authenticateUser}>
                    <img className="mb-4" src={logo} alt="" width="72" height="72"></img>
                    <h1 className="h3 mb-3 font-weight-normal mb-4">Please sign in</h1>
                    <input type="email" id="inputEmail" className="form-control" placeholder="Email address" value={email} onChange={(e) =>setEmail(e.target.value)} required="" autoFocus=""></input>
                   
                    <input type="password" id="inputPassword" className="form-control" placeholder="Password" value={password} onChange={(e) =>setPassword(e.target.value)} required=""></input>
                    
                    <button className="btn btn-lg btn-primary btn-block my-4" type="submit">Sign in</button>
                    <div className="checkbox my-4">
                        <label>
                            <input type="checkbox" value="remember-me"></input> Remember me
                        </label>
                    </div>
                </form>
            </div>
        </>
    )
}

export default Login