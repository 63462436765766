import { Container } from '@mui/material';
import DashboardNavbar from '../components/DashboardNavbar';
import '../assets/app.css'
import { Link } from 'react-router-dom';

function Dashboard() {
  return (
    <>
      <div className="container dashboard">
        <DashboardNavbar />
        <div className="row mt-5 justify-content-center">
          <div className="col-md-auto mx-5 d-flex justify-content-center">
            <div className="card mt-4 userCard justify-content-md-center" >
              <div className="card-body">
                <h5 className="card-title">Users</h5>
                <p className="card-text">There are currently: null users <br></br> Management tool to add, edit and delete users</p>
                <Link to="/Users">
                  <button className="btn btn-primary">Manage Users</button>
                </Link>
              </div>
            </div>
          </div>

          <div className="col-md-auto mx-5 d-flex justify-content-center">
            <div className="card mt-4 userCard">
              <div className="card-body">
                <h5 className="card-title">Pieces</h5>
                <p className="card-text">Upload, manage, and view Music pieces on the dashboard</p>
                <Link to="/Files">
                  <button className="btn btn-primary">Manage Pieces</button>
                </Link>
              </div>
            </div>
          </div>

          <div className="col-md-auto mx-5 d-flex justify-content-center">
            <div className="card mt-4 userCard">
              <div className="card-body">
                <h5 className="card-title">Analyses</h5>
                <p className="card-text">Analyze and search for specific trends</p>
                <Link to="/Files">
                  <button className="btn btn-primary">Analyze Piece</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Dashboard