import { useState, useEffect } from 'react';
import DashboardNavbar from './DashboardNavbar';
import '../assets/app.css';
import { useNavigate } from 'react-router'
import axios from 'axios';


function CreateUser() {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [role, setRole] = useState('');
    const [password, setPassword] = useState('');

    const navigate = useNavigate();
    async function formValidation(event) {
        event.preventDefault();
        const data = {
            email: email,
            password: password,
            firstName: firstName,
            lastName: lastName,
            role: role,
        }

        var res = await axios.post('https://codetta-back-end.vercel.app/register', data) //http://localhost:4000/register
        if(res.data.status === 'ok'){
            setFirstName('')
            setLastName('');
            setEmail('');
            setPassword('');
            setRole('');
            window.location.reload(); 
        }
        console.log(res);
    };


    return (

        <>
            <div className="container dashboard">
                <DashboardNavbar />
                <button type="button" className="btn btn-primary my-4 " data-bs-toggle="modal" data-bs-target="#createUser">
                    Add new user
                </button>

                <div className="modal fade" id="createUser" tabIndex="-1" aria-labelledby="createuserlabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <form className="needs-validation" onSubmit={formValidation} noValidate>
                            <div className="modal-content">
                                <div className='modal-error'></div>
                                <div className="modal-header">
                                    <h5 className="modal-title" id="createuserlabel">User Registration</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className="input-group mb-4">
                                        <span className="input-group-text">First and last name</span>
                                        <input type="text" aria-label="First name" className="form-control" value={firstName} onChange={(e) => setFirstName(e.target.value)}></input>
                                        <input type="text" aria-label="Last name" className="form-control" value={lastName} onChange={(e) => setLastName(e.target.value)}></input>
                                    </div>
                                    <div className="input-group mb-4">
                                        <span className="input-group-text">Email</span>
                                        <input type="email" className="form-control" id="email" value={email} placeholder="" onChange={(e) => setEmail(e.target.value)} required></input>
                                    </div>
                                    <div className="input-group mb-4">
                                        <span className="input-group-text">Password</span>
                                        <input type="text" className="form-control" id="password" value={password} placeholder="" onChange={(e) => setPassword(e.target.value)} required></input>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="Super User" id="defaultCheck1" onChange={(e) => setRole(e.target.value)}></input>
                                        <label className="form-check-label" htmlFor="defaultCheck1">
                                            Super User (can access the admin dashboard)
                                        </label>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="submit" className="btn btn-primary">Save changes</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CreateUser