import Summary from "./Summary";
import { useState } from "react";
function Coda() {
    const [start, setStart] = useState(0)//!introOne ? 0 : introOne.measures.start
    const [end, setEnd] = useState(0)

    const handleMeasuresStart = (val) => {
        setStart(val);
    }

    const handleMeasuresEnd = (val) => {
        setEnd(Number(val));
    }
    
    return (
        <>
            <div className="fs-2 fw-light text-muted mt-5 pt-5" id="intro">Coda</div>
            <hr className='pt-0 pb-0' />

           
            <div className="card my-5">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-9 d-flex align-items-center"><h5 className="fw-normal">Measures:</h5></div>
                            <div className="col-md-1">
                                <input className="form-control text-center px-0" value={start === null ? 0 : start} onChange={(e) => { handleMeasuresStart(e.target.value) }}></input>
                            </div>
                            <div className="col-md-1 text-center d-flex align-items-center justify-content-center">
                                to
                            </div>
                            <div className="col-md-1">
                                <input className="form-control text-center px-0" value={end === null ? 0 : end} onChange={(e) => { handleMeasuresEnd(e.target.value) }}></input>
                            </div>
                        </div>
                    </div>
                </div>

            <div className="card my-5">
                <div className="card-body">
                    <h5 className="card-title ">Pitch Class Collection</h5>
                    <div className="input-group input-group-lg my-4">
                        <input type="text" className="form-control" placeholder="C" aria-describedby="inputGroup-sizing-lg"></input>
                    </div>
                    <h5 className="card-title mt-5">Central Pitch Class</h5>
                    <div className="input-group input-group-lg my-4">
                        <input type="text" className="form-control" placeholder="C - D♭ - D♯/E♭ - E - F# - G - A - B♭" aria-label="Sizing example input"
                            aria-describedby="inputGroup-sizing-lg"></input>
                    </div>
                </div>
            </div>
            
            <Summary show={true}/>
        </>
    )
}

export default Coda