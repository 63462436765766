import { gql } from "@apollo/client";

const ADD_PIECEOVERVIEW = gql`
  mutation ADD_PIECEOVERVIEW(
    $composer: ComposerInput,
    $years: YearsInput,
    $number_of_movements: Int,
  ) {
    addPieceOverview(
      composer: $composer,
      years: $years,
      number_of_movements: $number_of_movements
    ) {
      id
    composer{first_name, last_name}
    years{composition_year, publication_year}
    number_of_movements
    }
  }
`;

const UPDATE_PIECEOVERVIEW = gql`
mutation UPDATE_PIECEOVERVIEW($id: ID!, 
    $composer: ComposerUpdate,
    $years: YearsUpdate,
    $number_of_movements: Int){
        updatePieceOverview(id: $id,
            composer: $composer,
            years: $years,
            number_of_movements: $number_of_movements,
        ){
            composer{first_name, last_name}
            years{composition_year, publication_year}
            number_of_movements
        }
    
}
`


export { ADD_PIECEOVERVIEW, UPDATE_PIECEOVERVIEW };

//ADD_PIECEOVERVIEW(varibles: {composer:{first_name: bob, last_name:marley}})