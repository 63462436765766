import React, { useState } from 'react';
import Slide from '@mui/material/Slide';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';

function Sentence({sentence, intro}) {
  const [secondBasicIdeaChecked, setSecondBasicIdeaChecked] = useState(false);
  const [thirdBasicIdeaChecked, setThirdBasicIdeaChecked] = useState(false);
  return (
    <>
    <Slide direction="up" in={sentence && intro} mountOnEnter unmountOnExit>
      <div className="card my-5">
        <div className="card-body">
          <h5 className="card-title">Sentence</h5>
          <style>
            {`
            .small-input {
                width: 50px; 
            }
            .form-section {
                display: flex;
                align-items: center;
                background-color: #f8f8f8;
                padding: 10px;
                width: 100%;
            }
            .measures-container {
                margin-right: 10px;
            }
            .bigGap {
                margin-bottom: 60px; 
            }
            .smallGap{
                margin-bottom: 10px;
            }
            `}
          </style>
          <div className="d-flex flex-column">
            <Grid container direction="row" alignItems="center" className="form-section smallGap">
                <Typography>Basic Idea</Typography>
                <div className="d-flex align-items-center ms-auto">
                    <div className="measures-container">
                      <Typography className="m-0 me-2">measures</Typography>
                    </div>
                    <TextField type="text" variant="outlined" size="small" className="me-2 small-input" />
                    <Typography className="m-0 me-2">to</Typography>
                    <TextField type="text" variant="outlined" size="small" className="small-input" />
                </div>
            </Grid>

            <Grid container direction="row" alignItems="center" className="form-section ">
                <Typography>
                  <Checkbox
                      checked={secondBasicIdeaChecked}
                      onChange={() => setSecondBasicIdeaChecked(!secondBasicIdeaChecked)}
                  />
                  Basic Idea
                </Typography>
            </Grid>

            <Grid container direction="row" alignItems="center" className="form-section bigGap">
                <Typography>
                  <Checkbox
                      checked={thirdBasicIdeaChecked}
                      onChange={() => setThirdBasicIdeaChecked(!thirdBasicIdeaChecked)}
                  />
                  Basic Idea Prime
                </Typography>
                <div className="d-flex align-items-center ms-auto">
                    <div className="measures-container">
                      <Typography className="m-0 me-2">measures</Typography>
                    </div>
                    <TextField type="text" variant="outlined" size="small" className="me-2 small-input" />
                    <Typography className="m-0 me-2">to</Typography>
                    <TextField type="text" variant="outlined" size="small" className="small-input" />
                </div>
            </Grid>

            <Grid container direction="row" alignItems="center" className="form-section smallGap">
                <Typography>Continuation</Typography>
                <div className="d-flex align-items-center ms-auto">
                    <div className="measures-container">
                      <Typography className="m-0 me-2">measures</Typography>
                    </div>
                    <TextField type="text" variant="outlined" size="small" className="me-2 small-input" />
                    <Typography className="m-0 me-2">to</Typography>
                    <TextField type="text" variant="outlined" size="small" className="small-input" />
                </div>
            </Grid>
            
            <Grid container direction="row" alignItems="center" className="form-section bigGap">
                <Typography>Terminus</Typography>
                <div className="d-flex align-items-center ms-auto">
                    <div className="measures-container">
                      <Typography className="m-0 me-2">measure:</Typography>
                    </div>
                    <TextField type="text" variant="outlined" size="small" className="me-2 small-input" />
                    <Typography className="m-0 me-2">beat:</Typography>
                    <TextField type="text" variant="outlined" size="small" className="small-input" />
                </div>
            </Grid>

            <Grid container direction="row" alignItems="center" className="form-section">
                <Typography>Winding-down technique</Typography>
                <div className="d-flex align-items-center ms-auto">
                    <div className="measures-container">
                      <Typography className="m-0 me-2">measures</Typography>
                    </div>
                    <TextField type="text" variant="outlined" size="small" className="me-2 small-input" />
                    <Typography className="m-0 me-2">to</Typography>
                    <TextField type="text" variant="outlined" size="small" className="small-input" />
                </div>
            </Grid>
          </div>
        </div>
      </div>
    </Slide>
    </>
  )
}

export default Sentence