import { gql } from "@apollo/client";
export const ADD_MOFR = gql`
mutation addMomentOfFormalRupture(
    $initial_MOFR_measure: Int,
    $initial_MOFR_location: String,
    $has_interpolation: Boolean,
    $num_of_interpolations: Int,
    $has_episodes: Boolean,
    $num_of_episodes: Int,
    $summary: String
){
    addMomentOfFormalRupture(
        initial_MOFR_measure: $initial_MOFR_measure,
    initial_MOFR_location: $initial_MOFR_location,
    has_interpolation: $has_interpolation,
    num_of_interpolations: $num_of_interpolations,
    has_episodes: $has_episodes,
    num_of_episodes: $num_of_episodes,
    summary: $summary
    ){
        id
        initial_MOFR_measure
        initial_MOFR_location
        has_interpolation
        num_of_interpolations
        has_episodes
        num_of_episodes
        summary
    }
}
`