import { Slide } from "@mui/material"
import SectionEnable from "./SectionEnable"
import ThemeType from "./dropDownComponents/ThemeType"
import Summary from "./Summary"
import { useState, useEffect } from "react"
import { useMutation } from '@apollo/client';
import { ADD_BASIC } from '../mutations/basicMutations';
import { ADD_SENTENCE } from '../mutations/sentenceMutations';
import { ADD_PERIOD } from '../mutations/periodMutations';
import { ADD_HYBRID } from '../mutations/hybridMutations';
import { ADD_TERNARY } from '../mutations/ternaryMutations';
import { UPDATE_MAIN_THEME_TWO } from "../mutations/mainThemeTwoMutations"
function MainThemeTwo({ mainThemeTwo, mainTwo }) {
    const [themeType, setThemeType] = useState(mainThemeTwo === null ? false : (mainThemeTwo.organized_theme_type));
    const [updateMainThemeTwo] = useMutation(UPDATE_MAIN_THEME_TWO);

    const [summary, setSummary] = useState(!mainThemeTwo ? "" : mainThemeTwo.summary)
    const [basic, setBasic] = useState(!mainThemeTwo || !mainThemeTwo.basic ? null : mainThemeTwo.basic.id);
    const [sentence, setSentence] = useState(!mainThemeTwo || !mainThemeTwo.sentence ? null : mainThemeTwo.sentence.id);
    const [period, setPeriod] = useState(!mainThemeTwo || !mainThemeTwo.period ? null : mainThemeTwo.period.id);
    const [hybrid, setHybrid] = useState(!mainThemeTwo || !mainThemeTwo.hybrid ? null : mainThemeTwo.hybrid.id);
    const [ternary, setTernary] = useState(!mainThemeTwo || !mainThemeTwo.ternary ? null : mainThemeTwo.ternary.id);

    const [addBasic] = useMutation(ADD_BASIC);
    const [addSentence] = useMutation(ADD_SENTENCE);
    const [addPeriod] = useMutation(ADD_PERIOD);
    const [addHybrid] = useMutation(ADD_HYBRID);
    const [addTernary] = useMutation(ADD_TERNARY);

    const handleMainThemeTwoUpdate = () => {
        updateMainThemeTwo({ variables: { id: mainThemeTwo.id, organized_theme_type: themeType, summary, basic, sentence, period, hybrid, ternary } })
        //console.log('start: ' + start + ' end: ' + end)
    }

    useEffect(() => {
        if (mainThemeTwo) {
            handleMainThemeTwoUpdate();
        }
    }, [themeType, summary, basic, sentence, period, hybrid, ternary]);


    const handleThemeEnable = () => {
        setThemeType(!themeType);

    }
    const handleBasic = async () => {
        var basicResult = await addBasic();
        setBasic(basicResult.data.addBasic.id);
    };
    const handleSentence = async () => {
        var sentenceResult = await addSentence();
        setSentence(sentenceResult.data.addSentence.id)
    }
    const handlePeriod = async () => {
        var periodResult = await addPeriod();
        setPeriod(periodResult.data.addPeriod.id);
    }
    const handleHybrid = async () => {
        var hybridResult = await addHybrid();
        setHybrid(hybridResult.data.addHybrid.id);
    }
    const handleTernary = async () => {
        var ternaryResult = await addTernary();
        setTernary(ternaryResult.data.addTernary.id);
    }

    return (
        <>
            <ThemeType show={mainTwo} checked={themeType} handleChange={handleThemeEnable} component={mainThemeTwo}
                handleBasic={handleBasic} basicId={basic}
                handleSentence={handleSentence} sentenceId={sentence}
                handlePeriod={handlePeriod} periodId={period}
                handleHybrid={handleHybrid} hybridId={hybrid}
                handleTernary={handleTernary} ternaryId={ternary}
            />
            <Summary show={mainTwo} text={summary} setText={setSummary} />
        </>
    )
}

export default MainThemeTwo