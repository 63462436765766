import SectionEnable from "./SectionEnable";
import { Slide } from "@mui/material";
import ThemeType from "./dropDownComponents/ThemeType";
import Summary from "./Summary";
import { useState, useEffect } from 'react';
import { UPDATE_NESTED_SUBORDINATE_THEME } from "../mutations/nestedSubordinateThemeMutations";
import { useMutation } from '@apollo/client';
import { ADD_BASIC } from '../mutations/basicMutations';
import { ADD_SENTENCE } from '../mutations/sentenceMutations';
import { ADD_PERIOD } from '../mutations/periodMutations';
import { ADD_HYBRID } from '../mutations/hybridMutations';
import { ADD_TERNARY } from '../mutations/ternaryMutations';

function LargeScaleSbTheme({lsTheme, lSubTheme, handleLsTheme}) {
  const [themeType, setThemeType] = useState(lSubTheme === null ? false : (lSubTheme.organized_theme_type));
  const [updateLsSubTheme] = useMutation(UPDATE_NESTED_SUBORDINATE_THEME);

  const [summary, setSummary] = useState(!lSubTheme ? "" : lSubTheme.summary);
  const [basic, setBasic] = useState(!lSubTheme || !lSubTheme.basic ? null : lSubTheme.basic.id);
  const [sentence, setSentence] = useState(!lSubTheme || !lSubTheme.sentence ? null : lSubTheme.sentence.id);
  const [period, setPeriod] = useState(!lSubTheme || !lSubTheme.period ? null : lSubTheme.period.id);
  const [hybrid, setHybrid] = useState(!lSubTheme || !lSubTheme.hybrid ? null : lSubTheme.hybrid.id);
  const [ternary, setTernary] = useState(!lSubTheme || !lSubTheme.ternary ? null : lSubTheme.ternary.id);

  const [addBasic] = useMutation(ADD_BASIC);
  const [addSentence] = useMutation(ADD_SENTENCE);
  const [addPeriod] = useMutation(ADD_PERIOD);
  const [addHybrid] = useMutation(ADD_HYBRID);
  const [addTernary] = useMutation(ADD_TERNARY);

  
  const handleLsSbThemeUpdate = () => {
    updateLsSubTheme({ variables: { id: lSubTheme.id, organized_theme_type: themeType, summary, basic, sentence, period, hybrid, ternary } })
}

useEffect(() => {
    if (lsTheme) {
      handleLsSbThemeUpdate();
    }
}, [themeType, summary, basic, sentence, period, hybrid, ternary]);

  return (
    <>
     <SectionEnable show={true} checked={lsTheme} title={'Large Scale Subordinate Theme'} handleChange={handleLsTheme} />
            <ThemeType show={lsTheme} />
            <Summary show={lsTheme} text={summary} setText={setSummary}/>
    </>
  )
}

export default LargeScaleSbTheme