import { gql } from "@apollo/client";

const ADD_SUBORDINATE_THEME_TWO = gql`
  mutation ADD_SUBORDINATE_THEME_TWO(
    $organized_theme_type: Boolean,
    $basic: ID,
    $sentence: ID,
    $period: ID,
    $hybrid: ID,
    $ternary: ID,
    $other: ID,
    $summary: String
  ){
    addSubordinateThemeTwo(
      organized_theme_type: $organized_theme_type,
      basic: $basic,
      sentence: $sentence,
      period: $period,
      hybrid: $hybrid,
      ternary: $ternary,
      other: $other,
      summary: $summary
    ) {
      id
    }
  }
`;

const UPDATE_SUBORDINATE_THEME_TWO = gql`
  mutation UPDATE_SUBORDINATE_THEME_TWO(
    $id: ID!,
    $organized_theme_type: Boolean,
    $basic: ID,
    $sentence: ID,
    $period: ID,
    $hybrid: ID,
    $ternary: ID,
    $other: ID,
    $summary: String
  ){
    updateSubordinateThemeTwo(
      id: $id,
      organized_theme_type: $organized_theme_type,
      basic: $basic,
      sentence: $sentence,
      period: $period,
      hybrid: $hybrid,
      ternary: $ternary,
      other: $other,
      summary: $summary
    ) {
      id
    }
  }
`;

const DELETE_SUBORDINATE_THEME_TWO = gql`
  mutation DELETE_SUBORDINATE_THEME_TWO($id: ID!){
    deleteSubordinateThemeTwo(id: $id){
      id
    }
  }
`;

export { ADD_SUBORDINATE_THEME_TWO, UPDATE_SUBORDINATE_THEME_TWO, DELETE_SUBORDINATE_THEME_TWO }
