import Slide from '@mui/material/Slide'
import ThemeType from './dropDownComponents/ThemeType';
import Summary from './Summary';
import SectionEnable from './SectionEnable';
import { useState, useEffect } from 'react'
import { UPDATE_INTRODUCTION_ONE } from "../mutations/introductionOneMutations";
import { useMutation } from '@apollo/client';
import { ADD_BASIC } from '../mutations/basicMutations';
import { ADD_SENTENCE } from '../mutations/sentenceMutations';
import { ADD_PERIOD } from '../mutations/periodMutations';
import { ADD_HYBRID } from '../mutations/hybridMutations';
import { ADD_TERNARY } from '../mutations/ternaryMutations';

function IntroductionOne({ intro, introOne, handleIntro }) {
    const [start, setStart] = useState(!introOne ? 0 : introOne.measures.start)
    const [end, setEnd] = useState(!introOne ? 0 : introOne.measures.end)
    const [themeType, setThemeType] = useState(introOne === null ? false : (introOne.organized_theme_type));
    const [updateIntroOne] = useMutation(UPDATE_INTRODUCTION_ONE);

    const [summary, setSummary] = useState(!introOne ? "" : introOne.summary);
    const [basic, setBasic] = useState(!introOne || !introOne.basic ? null : introOne.basic.id);
    const [sentence, setSentence] = useState(!introOne || !introOne.sentence ? null : introOne.sentence.id);
    const [period, setPeriod] = useState(!introOne || !introOne.period ? null : introOne.period.id);
    const [hybrid, setHybrid] = useState(!introOne || !introOne.hybrid ? null : introOne.hybrid.id);
    const [ternary, setTernary] = useState(!introOne || !introOne.ternary ? null : introOne.ternary.id);

    const [addBasic] = useMutation(ADD_BASIC);
    const [addSentence] = useMutation(ADD_SENTENCE);
    const [addPeriod] = useMutation(ADD_PERIOD);
    const [addHybrid] = useMutation(ADD_HYBRID);
    const [addTernary] = useMutation(ADD_TERNARY);


    const handleIntroUpdate = () => {
        updateIntroOne({ variables: { id: introOne.id, measures: { start: Number(start), end: Number(end) }, organized_theme_type: themeType, summary, basic, sentence, period, hybrid, ternary } })
    }

    useEffect(() => {
        if (introOne) {
            handleIntroUpdate();
        }
    }, [start, end, themeType, summary, basic, sentence, period, hybrid, ternary]);

    const handleMeasuresStart = (val) => {
        setStart(val);
    }

    const handleMeasuresEnd = (val) => {
        setEnd(Number(val));
    }

    const handleThemeEnable = () => {
        setThemeType(!themeType);

    }
    const handleBasic = async () => {
        var basicResult = await addBasic();
        setBasic(basicResult.data.addBasic.id);
    };
    const handleSentence = async () => {
        var sentenceResult = await addSentence();
        setSentence(sentenceResult.data.addSentence.id)
    }
    const handlePeriod = async () => {
        var periodResult = await addPeriod();
        setPeriod(periodResult.data.addPeriod.id);
    }
    const handleHybrid = async () => {
        var hybridResult = await addHybrid();
        setHybrid(hybridResult.data.addHybrid.id);
    }
    const handleTernary = async () => {
        var ternaryResult = await addTernary();
        setTernary(ternaryResult.data.addTernary.id);
    }


    const handleOther = () => {

    }

    return (
        <>
            <SectionEnable title={'Introduction'} show={true} checked={intro} handleChange={handleIntro} />
            <Slide direction="up" in={intro} mountOnEnter unmountOnExit>
                <div className="card my-5">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-9 d-flex align-items-center"><h5 className="fw-normal">Measures:</h5></div>
                            <div className="col-md-1">
                                <input className="form-control text-center px-0" value={start === null ? 0 : start} onChange={(e) => { handleMeasuresStart(e.target.value) }}></input>
                            </div>
                            <div className="col-md-1 text-center d-flex align-items-center justify-content-center">
                                to
                            </div>
                            <div className="col-md-1">
                                <input className="form-control text-center px-0" value={end === null ? 0 : end} onChange={(e) => { handleMeasuresEnd(e.target.value) }}></input>
                            </div>
                        </div>
                    </div>
                </div>
            </Slide>
            <ThemeType show={intro} checked={themeType} handleChange={handleThemeEnable} component={introOne}
                handleBasic={handleBasic} basicId={basic}
                handleSentence={handleSentence} sentenceId={sentence}
                handlePeriod={handlePeriod} periodId={period}
                handleHybrid={handleHybrid} hybridId={hybrid}
                handleTernary={handleTernary} ternaryId={ternary}
            />
            <Summary show={intro} text={summary} setText={setSummary} />
        </>
    )
}

export default IntroductionOne