import Slide from '@mui/material/Slide'
function Summary({show, text, setText}) {
  return (
    <>
       <Slide direction="up" in={show} mountOnEnter unmountOnExit>
                <div className="card my-5 ">
                    <div className="card-body">
                        <h5 className="card-title ">Summary</h5>
                        <textarea className="form-control mt-4 textContent" id="exampleFormControlTextarea1" rows="3" 
                        placeholder="Piece summary.." value={text===null? "": text} onChange={(e)=>{setText(e.target.value)}}></textarea>
                    </div>
                </div>
            </Slide>
    </>
  )
}

export default Summary