import { gql } from "@apollo/client";

const ADD_INTRODUCTION_ONE = gql`
mutation addIntroductionOne(
                $measures: MeasuresInput,
                $pitch_class_collection: String,
                $central_pitch_class: String,
                $organized_theme_type: Boolean,
                $basic: ID,
                $sentence: ID,
                $period: ID,
                $hybrid: ID,
                $ternary: ID,
                $other: ID,
                $summary: String,
){
    addIntroductionOne(
            measures: $measures,
            pitch_class_collection: $pitch_class_collection,
            central_pitch_class: $central_pitch_class,
            organized_theme_type: $organized_theme_type,
            basic: $basic,
            sentence: $sentence,
            period: $period,
            hybrid: $hybrid,
            ternary: $ternary,
            other: $other,
            summary: $summary,
    ){
        id
        measures{start, end}
        pitch_class_collection
        central_pitch_class
        organized_theme_type
        basic{id}
        sentence{id}
        period{id}
        hybrid{id}
        ternary{id}
        other{id}
        summary
    }
}
`

const UPDATE_INTRODUCTION_ONE = gql`
mutation updateIntroductionOne(
                $id: ID!,
                $measures: MeasuresInput,
                $pitch_class_collection: String,
                $central_pitch_class: String,
                $organized_theme_type: Boolean,
                $basic: ID,
                $sentence: ID,
                $period: ID,
                $hybrid: ID,
                $ternary: ID,
                $other: ID,
                $summary: String,
){
    updateIntroductionOne(
            id: $id,
            measures: $measures,
            pitch_class_collection: $pitch_class_collection,
            central_pitch_class: $central_pitch_class,
            organized_theme_type: $organized_theme_type,
            basic: $basic,
            sentence: $sentence,
            period: $period,
            hybrid: $hybrid,
            ternary: $ternary,
            other: $other,
            summary: $summary,
    ){
        id
        measures{start, end}
        pitch_class_collection
        central_pitch_class
        organized_theme_type
        basic{id}
        sentence{id}
        period{id}
        ternary{id}
        hybrid{id}
        other{id}
        summary
    }
}
`

const DELETE_INTRODUCTION_ONE = gql`
mutation deleteIntroductionOne(
                $id: ID!,
){
    deleteIntroductionOne(
            id: $id,
    ){
        id
        measures{start, end}
        pitch_class_collection
        central_pitch_class
        organized_theme_type
        basic{id}
        sentence{id}
        period{id}
        ternary{id}
        other{id}
        summary
    }
}
`

export {ADD_INTRODUCTION_ONE, UPDATE_INTRODUCTION_ONE, DELETE_INTRODUCTION_ONE}