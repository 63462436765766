import { useState, useRef } from "react"
import Slide from '@mui/material/Slide'
import Summary from "./Summary";
import SectionEnable from "./SectionEnable";
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';

function MomentOfFormalRupture() {
    const [mofr, setmofr] = useState(false);
    const [interpolation, setInterpolation] = useState(false);
    const [episodes, setEpisodes] = useState(false);
    const containerRef = useRef(null);
    const [mofrName, setMofrName] = useState("None");

    const handleMOFR = () => {
        setmofr(!mofr);
    }
    const handleInter = () => {
        setInterpolation(!interpolation);
    }
    const handleEpisodes = () => {
        setEpisodes(!episodes);
    }
    const handleOption = (param) => {
        setMofrName(param);
    }
    return (
        <>
            <div className="fs-2 fw-light text-muted mt-5 pt-5" id="intro">Moment Of Formal Rupture</div>
            <hr className='pt-0 pb-0' />
            <SectionEnable show={true} checked={mofr} title={'Moment of Formal Rupture'} handleChange={handleMOFR} />

            <Slide direction="up" in={mofr} mountOnEnter unmountOnExit>
                <div className="card my-5">
                    <div className="card-body">
                        <h5 className="card-title ">Initial Moment of Rupture Location</h5>
                        <div className="container mt-5 mb-3 mofr py-3 pe-4" >
                            <Checkbox /> Cannot be pinpointed
                            <div className="row">
                                <div className="col-md-9">
                                    <Checkbox /> Is in an identifiable measure
                                </div>
                                <div className="col-md-2 me-0 text-end pt-2 d-flex flex-column">
                                    Measure
                                </div>
                                <div className="col-md-1 px-0 ">
                                    <TextField type="text" variant="outlined" size="small" className="me-2 small-input" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Slide>

            {/** replace with drop down menu later */}
            <Slide direction="up" in={mofr} mountOnEnter unmountOnExit>
                <div className="card my-5">
                    <div className="card-body">
                            <div className="fs-5 mb-4">The initial moment of rupture occurs: </div>
                            <div className="fs-5 mb-3">
                                <div className="dropdown">
                                    <a className='nav-link dropdown-toggle fs-5' id="navbarDarkDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        {mofrName}
                                    </a>
                                    <ul className="dropdown-menu dropdown-menu-light dropMenu " aria-labelledby="dropdownMenuButton2">
                                        <li><button className="dropdown-item" onClick={() => { handleOption("after the onset of a ST in the exposition") }}>after the onset of a ST in the exposition</button></li>
                                        <li><button className="dropdown-item" onClick={() => { handleOption("after a complete ST in the exposition") }}>after a complete ST in the exposition</button></li>
                                        <li><button className="dropdown-item" onClick={() => { handleOption("after the onset of the development section") }}>after the onset of the development section</button></li>
                                        <li><button className="dropdown-item" onClick={() => { handleOption("after a complete development") }}>after a complete development </button></li>
                                        <li><button className="dropdown-item" onClick={() => { handleOption("after the onset of the MT of the recapitulation") }}>after the onset of the MT of the recapitulation</button></li>
                                        <li><button className="dropdown-item" onClick={() => { handleOption("after the onset of a ST in the recapitulation") }}>after the onset of a ST in the recapitulation</button></li>
                                        <li><button className="dropdown-item" onClick={() => { handleOption("completeSTRecap") }}>after a complete ST in the recapitulation</button></li>
                                        <li><button className="dropdown-item" onClick={() => { handleOption("after a complete ST in the recapitulation") }}>other</button></li>
                                        <li><hr className="dropdown-divider" /></li>
                                        <li><button className="dropdown-item" onClick={() => { handleOption("None") }}>None</button></li>
                                    </ul>
                                </div>
                            </div>
                    </div>
                </div>
            </Slide>

            <div className="card my-5 ">
                <div className="card-body">
                    <div className="row ">
                        <div className="col-md-10">
                            <h5 className="card-title">Interpolation
                            </h5>
                        </div>
                        <div className="col-md-2 d-flex justify-content-end">
                            <div className="form-check form-switch">
                                <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" checked={interpolation} onChange={handleInter}></input>
                            </div>
                        </div>
                    </div>
                    <Slide direction="up" in={interpolation} container={containerRef.current} mountOnEnter unmountOnExit>
                        <div className="container mt-3 mb-3 mofr py-3 " >
                            Number of interpolations: <TextField type="text" variant="standard" size="small" className="me-2 small-input ms-2" />
                        </div>
                    </Slide>
                    <div className="row mt-5">
                        <div className="col-md-10 ">
                            <h5 className="card-title">Episodes
                            </h5>
                        </div>
                        <div className="col-md-2 d-flex justify-content-end">
                            <div className="form-check form-switch">
                                <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" checked={episodes} onChange={handleEpisodes}></input>
                            </div>
                        </div>
                    </div>
                    <Slide direction="up" in={episodes} container={containerRef.current} mountOnEnter unmountOnExit>
                        <div className="container mt-3 mb-3 mofr py-3" >
                            Number of Episodes: <TextField type="text" variant="standard" size="small" className="me-2 small-input ms-2" />
                        </div>
                    </Slide>
                </div>
            </div>
            <Summary show={true} />
        </>
    )
}

export default MomentOfFormalRupture