import SectionEnable from "./SectionEnable"
import Summary from "./Summary"
import { useState } from "react"
function Codettas() {
    const [codettas, setCodettas] = useState(false);

    const handleCodettas = ()=>{
        setCodettas(!codettas);
    }
    return (
        <>
            <div className="fs-2 fw-light text-muted mt-5 pt-5" id="intro">Codettas</div>
            <hr className='pt-0 pb-0' />
            <SectionEnable show={true} checked={codettas} handleChange={handleCodettas} title={'Codettas'}/>
            <Summary show={codettas}/>
        </>
    )
}

export default Codettas