import { gql } from "@apollo/client";

export const GET_MOVEMENT = gql`
query GET_MOVEMENT($id: [ID!]){
    movements(id: $id){
        id
                        title
                        introduction_1{
                            id
                        },
                        nested_intro{
                            id
                        }
                        introduction_2{
                            id
                        }
                        main_theme_1{
                            id
                        }
                        nested_main_theme{
                            id
                        }
                        main_theme_2{
                            id
                        }
                        main_theme_3{
                            id
                        }
                        transition_1{
                            id
                        }
                        nested_transition{
                            id
                        }
                        transition_2{
                            id
                        }
                        sb_theme_1{
                            id
                        }
                        nested_sb_theme{
                            id
                        }
                        sb_theme_2{
                            id
                        }
                        sb_theme_3{
                            id
                        }
                        codettas{
                            id
                        }
    }
}
`