import { gql } from '@apollo/client'

const GET_PIECES = gql`
query GET_PIECES{
    pieces{
    id
    piece_title
    last_updated_by{
      id
    }
    created_by{
      id
    }
    piece_overview{
      number_of_movements
    }
    deleted
  }
}
`;

const GET_PIECE = gql`
query GET_PIECE($id: ID!){
  piece(id: $id){
    id
    piece_title
    last_updated_by{
      email
    }
    movements{
      id
      title
      introduction_1{
          id
          measures{start, end}
          organized_theme_type
          summary
          basic{
            id
          }
          sentence{
            id
          }
          period{
            id
          }
          hybrid{
            id
          }
          ternary{
            id
          }
          other{
            id
          }
      },
      nested_intro{
          id
          organized_theme_type
          basic{id}
          sentence{id}
          period{id}
          ternary{id}
          hybrid{id}
          other{id}
          summary
      }
      introduction_2{
          id
          organized_theme_type
          basic{id}
          sentence{id}
          period{id}
          ternary{id}
          hybrid{id}
          other{id}
          summary
      }
      main_theme_1{
          id
      }
      nested_main_theme{
          id
          organized_theme_type
          basic{id}
          sentence{id}
          period{id}
          ternary{id}
          hybrid{id}
          other{id}
          summary

      }
      main_theme_2{
          id
          organized_theme_type
          basic{id}
          sentence{id}
          period{id}
          ternary{id}
          hybrid{id}
          other{id}
          summary
      }
      main_theme_3{
          id
          organized_theme_type
          basic{id}
          sentence{id}
          period{id}
          ternary{id}
          hybrid{id}
          other{id}
          summary
      }
      transition_1{
          id
          pitch_class_collection
          central_pitch_class
          organized_theme_type
          basic{id}
          sentence{id}
          period{id}
          ternary{id}
          hybrid{id}
          other{id}
          summary
      }
      nested_transition{
          id
          organized_theme_type
          basic{id}
          sentence{id}
          period{id}
          ternary{id}
          hybrid{id}
          other{id}
          summary
      }
      transition_2{
          id
          organized_theme_type
          basic{id}
          sentence{id}
          period{id}
          ternary{id}
          hybrid{id}
          other{id}
          summary
      }
      sb_theme_1{
          id
          organized_theme_type
          basic{id}
          sentence{id}
          period{id}
          ternary{id}
          hybrid{id}
          other{id}
          summary
      }
      nested_sb_theme{
          id
          organized_theme_type
          basic{id}
          sentence{id}
          period{id}
          ternary{id}
          hybrid{id}
          other{id}
          summary
      }
      sb_theme_2{
          id
          organized_theme_type
          basic{id}
          sentence{id}
          period{id}
          ternary{id}
          hybrid{id}
          other{id}
          summary
      }
      sb_theme_3{
          id
          organized_theme_type
          basic{id}
          sentence{id}
          period{id}
          ternary{id}
          hybrid{id}
          other{id}
          summary
      }
      codettas{
          id
      }
    }
    piece_overview{id}
    moment_of_formal_rupture{id}
    piece_overview{
      id
    }
    development{id}
    recapitulation{id}
    coda{id}
    deleted
  }
}
`
const GET_PAGINATED_PIECES = gql`
query GET_PAGINATED_PIECES($limit: Int, $offset: Int){
  paginatedPieces(limit: $limit, offset: $offset){
    id
    last_updated_by{
      id
      firstName
      lastName
    }
    piece_title
    createdAt
    updatedAt
  }
}
`
export { GET_PIECES, GET_PIECE, GET_PAGINATED_PIECES }