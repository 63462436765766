import { useState, useEffect } from "react"
import Slide from '@mui/material/Slide'
import ThemeType from "./dropDownComponents/ThemeType";
import Summary from "./Summary";
import SectionEnable from "./SectionEnable";
import IntroductionOne from "./IntroductionOne";
import IntroductionTwo from "./IntroductionTwo";
import LargeScaleIntroduction from "./LargeScaleIntroduction";
import { ADD_INTRODUCTION_ONE } from "../mutations/introductionOneMutations";
import { ADD_NESTED_INTRO } from "../mutations/nestedIntroMutations";
import { ADD_INTRODUCTION_TWO } from "../mutations/introductionTwoMutations";
import { UPDATE_MOVEMENT } from "../mutations/movementMutations";
import { DELETE_INTRODUCTION_ONE } from "../mutations/introductionOneMutations";
import { useMutation, useQuery } from "@apollo/client";

function MovementIntro({ movement }) {
    //console.log(movement)
    const [intro, setIntro] = useState(movement.introduction_1 === null ? false : true);
    const [lsIntro, setLsIntro] = useState(movement.nested_intro === null ? false : true);
    const [secondIntro, setSecondIntro] = useState(movement.introduction_2 === null ? false : true);


    const [updateMovement] = useMutation(UPDATE_MOVEMENT);
    const [addIntroOne] = useMutation(ADD_INTRODUCTION_ONE);
    const [addLsIntro] = useMutation(ADD_NESTED_INTRO);
    const [addSecondIntro] = useMutation(ADD_INTRODUCTION_TWO);
    const [deleteIntroOne] = useMutation(DELETE_INTRODUCTION_ONE);
    //when the intro is checked set it's property to true which will in turn show the slide transition
    const handleIntro = async () => {
        if (intro === false) {
            var introOneResult = await addIntroOne();
            await updateMovement({ variables: { id: movement.id, introduction_1: introOneResult.data.addIntroductionOne.id } })
        }
        else {
            deleteIntroOne({ variables: { id: movement.introduction_1.id } })
        }

        setIntro(!intro);
    }

    const handleLsIntro = async () => {
        if(lsIntro === false){
            var lsIntroResult = await addLsIntro();
            await updateMovement({ variables: { id: movement.id, nested_intro: lsIntroResult.data.addNestedIntro.id }});
        }
        else{
            //deleteLsIntro({ variables: { id: movement}});
        }
        setLsIntro(!lsIntro);
    }
    const handleSecondIntro = async () => {
        if(secondIntro === false){
            var introTwoResult = await addSecondIntro();
            await updateMovement({ variables: { id: movement.id, introduction_2: introTwoResult.data.addIntroductionTwo.id }});
        }else{

        }
        setSecondIntro(!secondIntro);
    }






    return (
        <>
            <div className="fs-2 fw-light text-muted pt-5" id="intro">Introduction One</div>
            <hr className='pt-0 pb-0' />
            {/*<SectionEnable title={'Introduction'} show={true} checked={intro} handleChange={handleIntro} />*/}

            <IntroductionOne intro={intro} introOne={movement.introduction_1} handleIntro={handleIntro}/>

            <LargeScaleIntroduction intro={intro} lsIntro={lsIntro} handleLsIntro={handleLsIntro} nestedIntro={movement.nested_intro}/>

            <IntroductionTwo intro={intro} secondIntro={secondIntro} introTwo={movement.introduction_2} handleSecondIntro={handleSecondIntro} />
        </>
    )
}

export default MovementIntro