import { useState, useEffect } from "react"
import Movements from "./Movements";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';

export default function MovementTabs({ movements }) {
    const [activeTab, setActiveTab] = useState(!movements[0] ? null : movements[0]);
    const [title, setTitle] = useState(activeTab.title)

    //use Effect so that it initializes the active tab to the first when a new one is created
    const handleMovemetTab = (e) => {
        setActiveTab(e);
    }
    useEffect(()=>{
        setTitle(activeTab.title);
    }, [activeTab])

    return (
        <>
            <ul className="nav nav-tabs pt-5 movement-tab">
                {activeTab != null && 
                    movements.map((movement) => {
                        return (
                            <li key={movement.id} className="nav-item">
                                <button className={`nav-link ${movement.id === activeTab.id ? "active" : ''}`} aria-current="page" onClick={() => (handleMovemetTab(movement))}>{movement.title}</button>
                            </li>
                        )

                    })
                }
            </ul>
            {activeTab != null && <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-3 d-flex align-items-center"><h5 className="fw-normal"> Movement Title: </h5></div>
                        <div className="col-md-8 ">
                        <TextField id="standard-basic" variant="standard" value={title} onChange={(e)=>{setTitle(e.target.value)}} fullWidth />
                        </div>
                        <div className="col-md-1">
                            <IconButton>
                            <DeleteForeverIcon color="error" />
                            </IconButton>
                        </div>
                    </div>
                </div>
            </div>}
            <Movements movements={movements} activeMovement={activeTab} />
        </>
    )
}
