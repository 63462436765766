import { gql } from "@apollo/client";

const ADD_MAIN_THEME_ONE = gql`
  mutation ADD_MAIN_THEME_ONE(
    $pitch_class_collection: String,
    $central_pitch_class: String,
    $organized_theme_type: Boolean,
    $basic: ID,
    $sentence: ID,
    $period: ID,
    $hybrid: ID,
    $ternary: ID,
    $other: ID,
    $summary: String
  ){
    addMainThemeOne(
      pitch_class_collection: $pitch_class_collection,
      central_pitch_class: $central_pitch_class,
      organized_theme_type: $organized_theme_type,
      basic: $basic,
      sentence: $sentence,
      period: $period,
      hybrid: $hybrid,
      ternary: $ternary,
      other: $other,
      summary: $summary
    ) {
      id
    }
  }
`;

const UPDATE_MAIN_THEME_ONE = gql`
  mutation UPDATE_MAIN_THEME_ONE(
    $id: ID!,
    $pitch_class_collection: String,
    $central_pitch_class: String,
    $organized_theme_type: Boolean,
    $basic: ID,
    $sentence: ID,
    $period: ID,
    $hybrid: ID,
    $ternary: ID,
    $other: ID,
    $summary: String
  ){
    updateMainThemeOne(
      id: $id,
      pitch_class_collection: $pitch_class_collection,
      central_pitch_class: $central_pitch_class,
      organized_theme_type: $organized_theme_type,
      basic: $basic,
      sentence: $sentence,
      period: $period,
      hybrid: $hybrid,
      ternary: $ternary,
      other: $other,
      summary: $summary
    ) {
      id
    }
  }
`;

const DELETE_MAIN_THEME_ONE = gql`
  mutation DELETE_MAIN_THEME_ONE($id: ID!){
    deleteMainThemeOne(id: $id){
      id
    }
  }
`;

export { ADD_MAIN_THEME_ONE, UPDATE_MAIN_THEME_ONE, DELETE_MAIN_THEME_ONE }
