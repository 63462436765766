import ThemeType from "./dropDownComponents/ThemeType"
import Summary from "./Summary"
import { useState, useEffect } from "react"
import { useMutation } from "@apollo/client"
import { Slide } from "@mui/material"
import { ADD_BASIC } from '../mutations/basicMutations';
import { ADD_SENTENCE } from '../mutations/sentenceMutations';
import { ADD_PERIOD } from '../mutations/periodMutations';
import { ADD_HYBRID } from '../mutations/hybridMutations';
import { ADD_TERNARY } from '../mutations/ternaryMutations';
import { UPDATE_SUBORDINATE_THEME_ONE } from "../mutations/subordinateThemeOneMutations"

function SbThemeOne({subordinateThemeOne}) {
console.log(subordinateThemeOne)
    const [updateSbThemeOne] = useMutation(UPDATE_SUBORDINATE_THEME_ONE);
    const [summary, setSummary] = useState(!subordinateThemeOne ? "" : subordinateThemeOne.summary);

    const handleSbOneUpdate = () => {
        updateSbThemeOne({ variables: { id: subordinateThemeOne.id, summary} })
    }

    useEffect(() => {
        if (subordinateThemeOne) {
            handleSbOneUpdate();
        }
    }, [summary]);

    return (
        <>
            <ThemeType show={true} />

            <div className="card my-5">
                <div className="card-body">
                    <h5 className="card-title ">Pitch Class Collection</h5>
                    <div className="input-group input-group-lg my-4">
                        <input type="text" className="form-control" placeholder="C" aria-describedby="inputGroup-sizing-lg"></input>
                    </div>
                    <h5 className="card-title mt-5">Central Pitch Class</h5>
                    <div className="input-group input-group-lg my-4">
                        <input type="text" className="form-control" placeholder="C - D♭ - D♯/E♭ - E - F# - G - A - B♭" aria-label="Sizing example input"
                            aria-describedby="inputGroup-sizing-lg"></input>
                    </div>
                </div>
            </div>
            <Summary show={true} text={summary} setText={setSummary}/>

        </>
    )
}

export default SbThemeOne