import { gql } from "@apollo/client";

const ADD_SUBORDINATE_THEME_THREE = gql`
  mutation ADD_SUBORDINATE_THEME_THREE(
    $organized_theme_type: Boolean,
    $basic: ID,
    $sentence: ID,
    $period: ID,
    $hybrid: ID,
    $ternary: ID,
    $other: ID,
    $summary: String
  ){
    addSubordinateThemeThree(
      organized_theme_type: $organized_theme_type,
      basic: $basic,
      sentence: $sentence,
      period: $period,
      hybrid: $hybrid,
      ternary: $ternary,
      other: $other,
      summary: $summary
    ) {
      id
    }
  }
`;

const UPDATE_SUBORDINATE_THEME_THREE = gql`
  mutation UPDATE_SUBORDINATE_THEME_THREE(
    $id: ID!,
    $organized_theme_type: Boolean,
    $basic: ID,
    $sentence: ID,
    $period: ID,
    $hybrid: ID,
    $ternary: ID,
    $other: ID,
    $summary: String
  ){
    updateSubordinateThemeThree(
      id: $id,
      organized_theme_type: $organized_theme_type,
      basic: $basic,
      sentence: $sentence,
      period: $period,
      hybrid: $hybrid,
      ternary: $ternary,
      other: $other,
      summary: $summary
    ) {
      id
    }
  }
`;

const DELETE_SUBORDINATE_THEME_THREE = gql`
  mutation DELETE_SUBORDINATE_THEME_THREE($id: ID!){
    deleteSubordinateThemeThree(id: $id){
      id
    }
  }
`;

export { ADD_SUBORDINATE_THEME_THREE, UPDATE_SUBORDINATE_THEME_THREE, DELETE_SUBORDINATE_THEME_THREE }
