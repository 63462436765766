import { gql } from "@apollo/client";
const ADD_MOVEMENT = gql`
mutation addMovement($title: String,
            $introduction_1: ID,
            $nested_intro: ID,
            $introduction_2: ID,
            $main_theme_1: ID,
            $nested_main_theme: ID,
            $main_theme_2: ID,
            $main_theme_3: ID,
            $transition_1: ID,
            $nested_transition: ID,
            $transition_2: ID,
            $sb_theme_1: ID,
            $nested_sb_theme: ID,
            $sb_theme_2: ID,
            $sb_theme_3: ID,
            $codettas: ID){
                addMovement(
                    title: $title,
                    introduction_1: $introduction_1,
                    nested_intro: $nested_intro,
                    introduction_2: $introduction_2,
                    main_theme_1: $main_theme_1,
                    nested_main_theme: $nested_main_theme,
                    main_theme_2: $main_theme_2,
                    main_theme_3: $main_theme_3,
                    transition_1: $transition_1,
                    nested_transition: $nested_transition,
                    transition_2: $transition_2,
                    sb_theme_1: $sb_theme_1,
                    nested_sb_theme: $nested_sb_theme,
                    sb_theme_2: $sb_theme_2,
                    sb_theme_3: $sb_theme_3,
                    codettas: $codettas){
                        id
                        title
                        introduction_1{
                            id
                        },
                        nested_intro{
                            id
                        }
                        introduction_2{
                            id
                        }
                        main_theme_1{
                            id
                        }
                        nested_main_theme{
                            id
                        }
                        main_theme_2{
                            id
                        }
                        main_theme_3{
                            id
                        }
                        transition_1{
                            id
                        }
                        nested_transition{
                            id
                        }
                        transition_2{
                            id
                        }
                        sb_theme_1{
                            id
                        }
                        nested_sb_theme{
                            id
                        }
                        sb_theme_2{
                            id
                        }
                        sb_theme_3{
                            id
                        }
                        codettas{
                            id
                        }
                        }
            }
`
 const UPDATE_MOVEMENT = gql`
mutation updateMovement($id: ID!,
            $title: String,
            $introduction_1: ID,
            $nested_intro: ID,
            $introduction_2: ID,
            $main_theme_1: ID,
            $nested_main_theme: ID,
            $main_theme_2: ID,
            $main_theme_3: ID,
            $transition_1: ID,
            $nested_transition: ID,
            $transition_2: ID,
            $sb_theme_1: ID,
            $nested_sb_theme: ID,
            $sb_theme_2: ID,
            $sb_theme_3: ID,
            $codettas: ID){
                updateMovement(
                    id: $id,
                    title: $title,
                    introduction_1: $introduction_1,
                    nested_intro: $nested_intro,
                    introduction_2: $introduction_2,
                    main_theme_1: $main_theme_1,
                    nested_main_theme: $nested_main_theme,
                    main_theme_2: $main_theme_2,
                    main_theme_3: $main_theme_3,
                    transition_1: $transition_1,
                    nested_transition: $nested_transition,
                    transition_2: $transition_2,
                    sb_theme_1: $sb_theme_1,
                    nested_sb_theme: $nested_sb_theme,
                    sb_theme_2: $sb_theme_2,
                    sb_theme_3: $sb_theme_3,
                    codettas: $codettas){
                        id,
                        title
                        introduction_1{
                            id
                        },
                        nested_intro{
                            id
                        }
                        introduction_2{
                            id
                        }
                        main_theme_1{
                            id
                        }
                        nested_main_theme{
                            id
                        }
                        main_theme_2{
                            id
                        }
                        main_theme_3{
                            id
                        }
                        transition_1{
                            id
                        }
                        nested_transition{
                            id
                        }
                        transition_2{
                            id
                        }
                        sb_theme_1{
                            id
                        }
                        nested_sb_theme{
                            id
                        }
                        sb_theme_2{
                            id
                        }
                        sb_theme_3{
                            id
                        }
                        codettas{
                            id
                        }
                        }
            }
`
export {ADD_MOVEMENT, UPDATE_MOVEMENT}