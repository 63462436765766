import logo from '../assets/logo.png';
import '../assets/app.css'
import { useState, useEffect } from 'react';
import { UPDATE_PIECE } from '../mutations/pieceMutations';
import { useMutation, useSubscription } from '@apollo/client';
import { PIECE_UPDATED_SUBSCRIPTION } from '../subscriptions/pieceSubscriptions';
import axios from 'axios';

function PieceNavbar({ piece }) {

  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [title, setTitle] = useState(piece.piece_title);
  const [updatePiece] = useMutation(UPDATE_PIECE);
  const { data } = useSubscription(PIECE_UPDATED_SUBSCRIPTION, { variables: { id: piece.id, piece_title: title } });
 
  async function getUserInfo() {
    try {
      var res = await axios.get('https://codetta-back-end.vercel.app/auth', {
        headers: { 'x-access-token': localStorage.getItem("token") }
      });
      setFirstName(res.data.firstName);
      setLastName(res.data.lastName);
      setEmail(res.data.email);
    } catch (error) {
      console.error("Error fetching user info:", error);
    }
  }
  useEffect(() => {
    getUserInfo();
  }, [])

  useEffect(() => {
    if (data && data.pieceUpdated) {
      setTitle(data.pieceUpdated.piece_title);
      // Do something with the pieceTitle, e.g., update the component state
    }
  }, [data]);
  async function updateTitle() {
    await updatePiece({ variables: { id: piece.id, piece_title: title, created_by: "64c5b448b47a1936db7901d8", piece_overview: "65090787d5661f7e4efde2aa" } })
    //console.log(`update result: `+ updateRes);
  }
  //render back end first to update the front end
  return (
    <>
      <nav className="navbar navbar-dark navbar-expand-lg">
        <div className="container-fluid">
          <a className="navbar-brand" href="/Files">
            <img src={logo} alt="" width="24" height="24" className="d-inline-block align-text-top mx-2" ></img>
            Codetta
          </a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarText">
            <ul className="navbar-nav mx-auto mb-2 mb-lg-0 flex-column">
              <div className="row">
                <form>
                  <input className='titleInput' value={title} onChange={(e) => setTitle(e.target.value)} onKeyUp={updateTitle}></input>
                </form>
              </div>
            </ul>
            <span className="navbar-text nav-item dropdown mt-2">
              <a className="nav-link dropdown-toggle" href="/Homepage" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                {firstName} {lastName}
              </a>
              <p>{email}</p>
              <ul className="dropdown-menu  dropdown-menu-dark" aria-labelledby="navbarDropdownMenuLink">
                <li><a className="dropdown-item" href="#">Settings</a></li>
                <li><a className="dropdown-item" href="/">Logout</a></li>
              </ul>
            </span>
          </div>
        </div>
      </nav>
    </>
  )
}

export default PieceNavbar