import { gql } from "@apollo/client";

const PIECE_UPDATED_SUBSCRIPTION = gql`
subscription PIECE_UPDATED($id: ID!,
    $piece_title: String){
    pieceUpdated(id: $id,
    piece_title: $piece_title){
        piece_title
    }
}
`
export { PIECE_UPDATED_SUBSCRIPTION } 