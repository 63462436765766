import { gql } from "@apollo/client";

const ADD_MAIN_THEME_TWO = gql`
  mutation ADD_MAIN_THEME_TWO(
    $organized_theme_type: Boolean,
    $basic: ID,
    $sentence: ID,
    $period: ID,
    $hybrid: ID,
    $ternary: ID,
    $other: ID,
    $summary: String
  ){
    addMainThemeTwo(
      organized_theme_type: $organized_theme_type,
      basic: $basic,
      sentence: $sentence,
      period: $period,
      hybrid: $hybrid,
      ternary: $ternary,
      other: $other,
      summary: $summary
    ) {
      id
    }
  }
`;

const UPDATE_MAIN_THEME_TWO = gql`
  mutation UPDATE_MAIN_THEME_TWO(
    $id: ID!,
    $organized_theme_type: Boolean,
    $basic: ID,
    $sentence: ID,
    $period: ID,
    $hybrid: ID,
    $ternary: ID,
    $other: ID,
    $summary: String
  ){
    updateMainThemeTwo(
      id: $id,
      organized_theme_type: $organized_theme_type,
      basic: $basic,
      sentence: $sentence,
      period: $period,
      hybrid: $hybrid,
      ternary: $ternary,
      other: $other,
      summary: $summary
    ) {
      id
    }
  }
`;

const DELETE_MAIN_THEME_TWO = gql`
  mutation DELETE_MAIN_THEME_TWO($id: ID!){
    deleteMainThemeTwo(id: $id){
      id
    }
  }
`;

export { ADD_MAIN_THEME_TWO, UPDATE_MAIN_THEME_TWO, DELETE_MAIN_THEME_TWO }
