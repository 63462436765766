import { gql } from "@apollo/client";

const ADD_TRANSITION_TWO = gql`
  mutation ADD_TRANSITION_TWO(
    $organized_theme_type: Boolean,
    $basic: ID,
    $sentence: ID,
    $period: ID,
    $hybrid: ID,
    $ternary: ID,
    $other: ID,
    $summary: String
  ){
    addTransitionTwo(
      organized_theme_type: $organized_theme_type,
      basic: $basic,
      sentence: $sentence,
      period: $period,
      hybrid: $hybrid,
      ternary: $ternary,
      other: $other,
      summary: $summary
    ) {
      id
    }
  }
`;

const UPDATE_TRANSITION_TWO = gql`
  mutation UPDATE_TRANSITION_TWO(
    $id: ID!,
    $organized_theme_type: Boolean,
    $basic: ID,
    $sentence: ID,
    $period: ID,
    $hybrid: ID,
    $ternary: ID,
    $other: ID,
    $summary: String
  ){
    updateTransitionTwo(
      id: $id,
      organized_theme_type: $organized_theme_type,
      basic: $basic,
      sentence: $sentence,
      period: $period,
      hybrid: $hybrid,
      ternary: $ternary,
      other: $other,
      summary: $summary
    ) {
      id
    }
  }
`;

const DELETE_TRANSITION_TWO = gql`
  mutation DELETE_TRANSITION_TWO($id: ID!){
    deleteTransitionTwo(id: $id){
      id
    }
  }
`;

export { ADD_TRANSITION_TWO, UPDATE_TRANSITION_TWO, DELETE_TRANSITION_TWO }
