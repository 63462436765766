import Checkbox from '@mui/material/Checkbox';

function AlteredOptionComponents({checked, handleChange, title}) {
  return (
    <>
    <div className="row my-2">
                        <div className="col-md-10 ">
                            {title}
                        </div>
                        <div className="col-md-2 d-flex justify-content-end">
                            <div className="form-check form-switch">
                                {/*<input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" checked={checked} onChange={handleChange}></input>*/}
                                <Checkbox />
                            </div>
                        </div>
                    </div>
    </>
  )
}

export default AlteredOptionComponents