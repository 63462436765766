import { GET_USERS } from "../queries/userQueries"
import '../assets/Users.js'

function UserTable({ user }) {
    return (
        <>
            <tr>
                <td>{user.firstName}</td>
                <td>{user.lastName}</td>
                <td>{user.email}</td>
                <td>{user.superUser}</td>
                <td>
                    <button className="btn btn-sm btn-secondary mr-2"> Edit </button>
                </td>
            </tr>
        </>
    )
}

export default UserTable