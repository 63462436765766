import { gql } from "@apollo/client";

const ADD_HYBRID = gql`
mutation ADD_HYBRID($basic_idea: BasicIdeaInput,
            $contrasting_idea: ContrastingIdeaInput,
            $contrasting_idea_option: String,
            $terminus: TerminusInput,
            $winding_down_technique: WindingDownTechniqueInput,
            ){
                addHybrid(basic_idea: $basic_idea,
                    contrasting_idea: $contrasting_idea,
                    contrasting_idea_option: $contrasting_idea_option,
                    terminus: $terminus,
                    winding_down_technique: $winding_down_technique){
                        id
                }
            }
`

const UPDATE_HYBRID = gql`
mutation UPDATE_HYBRID($id: ID!,
            $basic_idea: BasicIdeaInput,
            $contrasting_idea: ContrastingIdeaInput,
            $contrasting_idea_option: String,
            $terminus: TerminusInput,
            $winding_down_technique: WindingDownTechniqueInput,
            ){
                updateHybrid(
                    id: $id,
                    basic_idea: $basic_idea,
                    contrasting_idea: $contrasting_idea,
                    contrasting_idea_option: $contrasting_idea_option,
                    terminus: $terminus,
                    winding_down_technique: $winding_down_technique){
                        id
                }
            }
`

const DELETE_HYBRID = gql`
mutation DELETE_HYBRID($id: ID!){
    deleteHybrid(id: $id){
        id
    }
}
`

export {UPDATE_HYBRID, ADD_HYBRID, DELETE_HYBRID}