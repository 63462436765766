import React, { useState, useEffect } from "react";
import { useMutation } from '@apollo/client';
import { UPDATE_TRANSITION_TWO } from "../mutations/transitionTwoMutations";
import { Slide } from "@mui/material";
import SectionEnable from "./SectionEnable";
import ThemeType from "./dropDownComponents/ThemeType";
import { ADD_BASIC } from '../mutations/basicMutations';
import { ADD_SENTENCE } from '../mutations/sentenceMutations';
import { ADD_PERIOD } from '../mutations/periodMutations';
import { ADD_HYBRID } from '../mutations/hybridMutations';
import { ADD_TERNARY } from '../mutations/ternaryMutations';

function TransitionTwo({ transitionTwo, transTwo, handleTransitionTwo }) {
    const [themeType, setThemeType] = useState(transTwo === null ? false : transTwo.organized_theme_type);
    const [summary, setSummary] = useState(!transTwo ? "" : transTwo.summary);
    const [basic, setBasic] = useState(!transTwo || !transTwo.basic ? null : transTwo.basic.id);
    const [sentence, setSentence] = useState(!transTwo || !transTwo.sentence ? null : transTwo.sentence.id);
    const [period, setPeriod] = useState(!transTwo || !transTwo.period ? null : transTwo.period.id);
    const [hybrid, setHybrid] = useState(!transTwo || !transTwo.hybrid ? null : transTwo.hybrid.id);
    const [ternary, setTernary] = useState(!transTwo || !transTwo.ternary ? null : transTwo.ternary.id);

    const [updateTransTwo] = useMutation(UPDATE_TRANSITION_TWO);

    
    const [addBasic] = useMutation(ADD_BASIC);
    const [addSentence] = useMutation(ADD_SENTENCE);
    const [addPeriod] = useMutation(ADD_PERIOD);
    const [addHybrid] = useMutation(ADD_HYBRID);
    const [addTernary] = useMutation(ADD_TERNARY);


    const handleTransTwoUpdate = () => {
        updateTransTwo({ variables: { id: transTwo.id, organized_theme_type: themeType, summary, basic, sentence, period, hybrid, ternary } });
    };

    useEffect(() => {
        if (transTwo) {
            handleTransTwoUpdate();
        }
    }, [themeType, summary, basic, sentence, period, hybrid, ternary]);

    const handleThemeEnable = () => {
        setThemeType(!themeType);
    };

    const handleBasic = async () => {
      var basicResult = await addBasic();
      setBasic(basicResult.data.addBasic.id);
  };
  const handleSentence = async () => {
      var sentenceResult = await addSentence();
      setSentence(sentenceResult.data.addSentence.id)
  }
  const handlePeriod = async () => {
      var periodResult = await addPeriod();
      setPeriod(periodResult.data.addPeriod.id);
  }
  const handleHybrid = async () => {
      var hybridResult = await addHybrid();
      setHybrid(hybridResult.data.addHybrid.id);
  }
  const handleTernary = async () => {
      var ternaryResult = await addTernary();
      setTernary(ternaryResult.data.addTernary.id);
  }
  

  const handleOther = () => {

  }
    return (
        <>
            <SectionEnable title={'Second Transition'} show={true} checked={transitionTwo} handleChange={handleTransitionTwo} />
            <ThemeType show={transitionTwo} checked={themeType} handleChange={handleThemeEnable} component={transTwo}
                handleBasic={handleBasic} basicId={basic}
                handleSentence={handleSentence} sentenceId={sentence}
                handlePeriod={handlePeriod} periodId={period}
                handleHybrid={handleHybrid} hybridId={hybrid}
                handleTernary={handleTernary} ternaryId={ternary} />

            <Slide direction="up" in={transitionTwo} mountOnEnter unmountOnExit>
                <div className="card my-5">
                    <div className="card-body">
                        <h5 className="card-title ">Pitch Class Collection</h5>
                        <div className="input-group input-group-lg my-4">
                            <input type="text" className="form-control" placeholder="C" aria-describedby="inputGroup-sizing-lg"></input>
                        </div>
                        <h5 className="card-title mt-5">Central Pitch Class</h5>
                        <div className="input-group input-group-lg my-4">
                            <input type="text" className="form-control" placeholder="C - D♭ - D♯/E♭ - E - F# - G - A - B♭" aria-label="Sizing example input"
                                aria-describedby="inputGroup-sizing-lg"></input>
                        </div>
                    </div>
                </div>
            </Slide>
        </>
    );
}

export default TransitionTwo;
