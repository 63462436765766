import logo from '../assets/logo.png';
import Chip from '@mui/material/Chip';
import '../assets/app.css'
import { Link } from 'react-router-dom';

function Homepage() {
  return (
    <>
      <div className="jumbotron img-fluid">
        <div className='container home text-right mt-4'>
          <nav className="navbar" >
            <div className="container-fluid">
              <a className="navbar-brand" href="#">
                <img src={logo} alt="" width="24" height="24" className="d-inline-block align-text-top mx-2" ></img>
                Codetta
              </a>
              <span className="navbar-text">
                <Link to="/Login">
                <Chip label="Sign In" color="primary" />
                </Link>
              </span>
            </div>
          </nav>
          <div className="container" id="jumbo_text">
            <h1 className="display-3 ">MANAGE YOUR</h1>
            <h1 className="display-3 ">DASHBOARD</h1>
          </div>
          <Link to="/Login">
          <button className="button">Sign In</button>
          </Link>
        </div>
      </div>
    </>
  )
}

export default Homepage