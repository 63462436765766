import Slide from '@mui/material/Slide'
function SectionEnable({ title, show, checked, handleChange }) {
    return (
        <>
        <Slide direction="up" in={show} mountOnEnter unmountOnExit>
            <div className="card my-5 option">
                <div className="card-body pb-1">
                    <div className="row">
                        <div className="col-md-10"><h5>{title}</h5></div>
                        <div className="col-md-2 d-flex justify-content-end"><div className="form-check form-switch">
                            <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" checked={checked} onChange={handleChange}></input>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            </Slide>
        </>
    )
}

export default SectionEnable