import ThemeType from './dropDownComponents/ThemeType'
import Summary from './Summary'
import { useState, useEffect } from 'react'
import { UPDATE_NESTED_MAIN_THEME } from "../mutations/nestedMainThemeMutations";
import { useMutation } from '@apollo/client';
import { ADD_BASIC } from '../mutations/basicMutations';
import { ADD_SENTENCE } from '../mutations/sentenceMutations';
import { ADD_PERIOD } from '../mutations/periodMutations';
import { ADD_HYBRID } from '../mutations/hybridMutations';
import { ADD_TERNARY } from '../mutations/ternaryMutations';
function LargeScaleMainTheme({nestedMain, lsMain}) {
    const [themeType, setThemeType] = useState(nestedMain === null ? false : (nestedMain.organized_theme_type));
    const [updateNestedMain] = useMutation(UPDATE_NESTED_MAIN_THEME);

    const [summary, setSummary] = useState(!nestedMain ? "" : nestedMain.summary)
    const [basic, setBasic] = useState(!nestedMain || !nestedMain.basic ? null : nestedMain.basic.id);
    const [sentence, setSentence] = useState(!nestedMain || !nestedMain.sentence ? null : nestedMain.sentence.id);
    const [period, setPeriod] = useState(!nestedMain || !nestedMain.period ? null : nestedMain.period.id);
    const [hybrid, setHybrid] = useState(!nestedMain || !nestedMain.hybrid ? null : nestedMain.hybrid.id);
    const [ternary, setTernary] = useState(!nestedMain || !nestedMain.ternary ? null : nestedMain.ternary.id);

    const [addBasic] = useMutation(ADD_BASIC);
    const [addSentence] = useMutation(ADD_SENTENCE);
    const [addPeriod] = useMutation(ADD_PERIOD);
    const [addHybrid] = useMutation(ADD_HYBRID);
    const [addTernary] = useMutation(ADD_TERNARY);

    const handleNestedMainUpdate =  () => {
        updateNestedMain({ variables: { id: nestedMain.id, organized_theme_type: themeType, summary, basic, sentence, period, hybrid, ternary } })
        //console.log('start: ' + start + ' end: ' + end)
    }

    useEffect(() => {
        if (nestedMain) {
            handleNestedMainUpdate();
        }
    }, [themeType, summary, basic, sentence, period, hybrid, ternary]);


    const handleThemeEnable = () => {
        setThemeType(!themeType);

    }
    const handleBasic = async () => {
        var basicResult = await addBasic();
        setBasic(basicResult.data.addBasic.id);
    };
    const handleSentence = async () => {
        var sentenceResult = await addSentence();
        setSentence(sentenceResult.data.addSentence.id)
    }
    const handlePeriod = async () => {
        var periodResult = await addPeriod();
        setPeriod(periodResult.data.addPeriod.id);
    }
    const handleHybrid = async () => {
        var hybridResult = await addHybrid();
        setHybrid(hybridResult.data.addHybrid.id);
    }
    const handleTernary = async () => {
        var ternaryResult = await addTernary();
        setTernary(ternaryResult.data.addTernary.id);
    }
    

  return (
    <>
    <ThemeType show={lsMain} checked={themeType} handleChange={handleThemeEnable} component={nestedMain}
                handleBasic={handleBasic} basicId={basic}
                handleSentence={handleSentence} sentenceId={sentence}
                handlePeriod={handlePeriod} periodId={period}
                handleHybrid={handleHybrid} hybridId={hybrid}
                handleTernary={handleTernary} ternaryId={ternary} 
            />
    <Summary show={lsMain} text={summary} setText={setSummary}/>
    </>
  )
}

export default LargeScaleMainTheme