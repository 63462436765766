import { gql } from "@apollo/client";

const ADD_BASIC = gql`
mutation ADD_BASIC($basic_idea: BasicIdeaInput,
                    $continuation: ContinuationInput,
                    $terminus: TerminusInput,
                    $winding_down_technique: WindingDownTechniqueInput,
                    $summary: String){
                        addBasic(basic_idea: $basic_idea,
                            continuation: $continuation,
                            terminus: $terminus,
                            winding_down_technique: $winding_down_technique,
                            summary: $summary){
                                id
                                basic_idea{start, end}

                            }
                    }
`

const UPDATE_BASIC = gql`
mutation UPDATE_BASIC($id: ID!,
                    $basic_idea: BasicIdeaInput,
                    $continuation: ContinuationInput,
                    $terminus: TerminusInput,
                    $winding_down_technique: WindingDownTechniqueInput,
                    $summary: String){
                        updateBasic(basic_idea: $basic_idea,
                            continuation: $continuation,
                            terminus: $terminus,
                            winding_down_technique: $winding_down_technique,
                            summary: $summary){
                                id
                                basic_idea{start, end}

                            }
                    }
`
const DELETE_BASIC = gql`
mutation DELETE_BASIC($id: ID!){
    deleteBasic(id: $id){
        id
    }
}
`

export { ADD_BASIC, UPDATE_BASIC, DELETE_BASIC }