import logo from '../assets/logo.png';
import '../assets/app.css'
import axios from 'axios';
import { useState, useEffect } from 'react';

function FormNavbar() {
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    async function getUserInfo() {
      try {
        var res = await axios.get('https://codetta-back-end.vercel.app/auth', {
          headers: { 'x-access-token': localStorage.getItem("token") }
        });
        setFirstName(res.data.firstName);
        setLastName(res.data.lastName);
        setEmail(res.data.email);
      } catch (error) {
        console.error("Error fetching user info:", error);
      }
    }
    useEffect(() => {
      getUserInfo();
    }, [])
    return (
        <>
            <nav className="navbar navbar-expand-lg">
                <div className="container-fluid">
                    <a className="navbar-brand" href="/Dashboard">
                        <img src={logo} alt="" width="24" height="24" className="d-inline-block align-text-top mx-2" ></img>
                        Codetta
                    </a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse my-3 text-center" id="navbarText">
                        <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
                            <div className="row">
                                <form>
                                    <input className="form-control me-2 w-100" type="search" placeholder="Search" aria-label="Search"></input>
                                </form>
                            </div>
                        </ul>

                        <span className="navbar-text nav-item dropdown mt-2">
                            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                {firstName} {lastName}
                            </a>
                            <p>{email}</p>
                            <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                <li><a className="dropdown-item" href="#">Settings</a></li>
                                <li><a className="dropdown-item" href="/">Logout</a></li>
                            </ul>
                        </span>
                    </div>
                </div>
            </nav>
        </>
    )
}

export default FormNavbar