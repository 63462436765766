import { useEffect, useState } from "react"
import Slide from '@mui/material/Slide'
import ThemeType from "./dropDownComponents/ThemeType";
import Summary from "./Summary";
import SectionEnable from "./SectionEnable";
import { ADD_SUBORDINATE_THEME_ONE } from "../mutations/subordinateThemeOneMutations";
import { ADD_SUBORDINATE_THEME_TWO } from "../mutations/subordinateThemeTwoMutations";
import { ADD_SUBORDINATE_THEME_THREE } from "../mutations/subordinateThemeThreeMutations";
import { ADD_NESTED_SUBORDINATE_THEME } from "../mutations/nestedSubordinateThemeMutations";
import { UPDATE_MOVEMENT } from "../mutations/movementMutations";
import { useMutation } from "@apollo/client";
import SbThemeOne from "./SbThemeOne";
import SbThemeTwo from "./SbThemeTwo";
import SbThemeThree from "./SbThemeThree";
import LargeScaleSbTheme from "./LargeScaleSbTheme";

function MovementSbTheme({ movement }) {
    const [lsTheme, setLsTheme] = useState(movement.nested_sb_theme === null ? false : true);
    const [sbOne, setSbOne] = useState(movement.sb_theme_1 === null ? false : true);
    const [sbTwo, setSbTwo] = useState(movement.sb_theme_2 === null ? false : true);
    const [sbThree, setSbThree] = useState(movement.sb_theme_3 === null ? false : true);

    const [updateMovement] = useMutation(UPDATE_MOVEMENT);
    const [addSbThemeOne] = useMutation(ADD_SUBORDINATE_THEME_ONE);
    const [addSbThemeTwo] = useMutation(ADD_SUBORDINATE_THEME_TWO)
    const [addSbThemeThree] = useMutation(ADD_SUBORDINATE_THEME_THREE);
    const [addNestedSbTheme] = useMutation(ADD_NESTED_SUBORDINATE_THEME);

    const handleSbOne = async () => {
        var sbThemeOneResult = await addSbThemeOne();
        await updateMovement({ variables: { id: movement.id, sb_theme_1: sbThemeOneResult.data.addSubordinateThemeOne.id } })
        setSbOne(!sbOne);
    }

    useEffect(() => {
        if (movement.sb_theme_1 === null) {
            handleSbOne();
        }
    }, []);

    const handleLsTheme = async () => {
        if (lsTheme === false) {
            var lsThemeResult =  await addNestedSbTheme();
            console.log(lsThemeResult)
            await updateMovement({ variables: { id: movement.id, nested_sb_theme: lsThemeResult.data.addNestedSubordinateTheme.id } })
        } else {
            //delete
        }
        setLsTheme(!lsTheme);
    }
    const handleSbTwo = async () => {
        if (sbOne === false) {
            var sbThemeTworesult = addSbThemeTwo();
            await updateMovement({ variables: { id: movement.id, sb_theme_2: await sbThemeTworesult.data.addSubordinateThemeTwo.id } })
        } else {
            //delete
        }
        setSbTwo(!sbTwo);
    }

 
    const handleSbThree = async () => {
        if (sbThree === false) {
            var sbThemeThreeResult = addSbThemeThree();
            await updateMovement({ variables: { id: movement.id, sb_theme_3: await sbThemeThreeResult.data.addSubordinateThemeThree.id } })
        } else {
            //delete
        }
        setSbThree(!sbThree);
    };

    return (
        <>
            <div className="fs-2 fw-light text-muted mt-5 pt-5" id="intro">Subordinate Theme One</div>
            <hr className='pt-0 pb-0' />
            <SbThemeOne sbOne={sbOne} subordinateThemeOne={movement.sb_theme_1} />

            <div className="fs-2 fw-light text-muted mt-5 pt-5" id="intro">Large-Scale Subordinate Theme </div>
            <hr className='pt-0 pb-0' />
            <SectionEnable show={true} checked={lsTheme} title={'Large Scale Subordinate Theme'} handleChange={handleLsTheme} />
            <ThemeType show={lsTheme} />
            <Summary show={lsTheme} />
            <LargeScaleSbTheme lsTheme={lsTheme} lSubTheme={movement.nested_sb_theme} handleLsTheme={handleLsTheme}/>


            <div className="fs-2 fw-light text-muted mt-5 pt-5" id="intro">Subordinate Theme Two</div>
            <hr className='pt-0 pb-0' />
            <SectionEnable show={true} checked={sbTwo} title={'Subordinate Theme Two'} handleChange={handleSbTwo} />
            <ThemeType show={sbTwo} />
            <Summary show={sbTwo} />

            {/* show only if subordinate theme 2 is enabled */}
            <Slide direction="up" in={sbTwo} mountOnEnter unmountOnExit>
                <div>
                    <div className="fs-2 fw-light text-muted mt-5 pt-5" id="intro">Subordinate Theme Three</div>
                    <hr className='pt-0 pb-0' />
                </div>
            </Slide>
            <SectionEnable show={sbTwo} checked={sbThree} title={'Subordinate Theme Three'} handleChange={handleSbThree} />
            <ThemeType show={sbTwo && sbThree} />
            <Summary show={sbTwo && sbThree} />
        </>
    )
}

export default MovementSbTheme