import { Slide } from "@mui/material";
import SectionEnable from "./SectionEnable";
import ThemeType from "./dropDownComponents/ThemeType";
import Summary from "./Summary";
import { useState, useEffect } from "react";
import { useMutation } from '@apollo/client';
import { ADD_BASIC } from '../mutations/basicMutations';
import { ADD_SENTENCE } from '../mutations/sentenceMutations';
import { ADD_PERIOD } from '../mutations/periodMutations';
import { ADD_HYBRID } from '../mutations/hybridMutations';
import { ADD_TERNARY } from '../mutations/ternaryMutations';
import { UPDATE_MAIN_THEME_THREE } from "../mutations/mainThemeThreeMutations";

function MainThemeThree({ mainThemeThree, mainThree, mainTwo }) {
    const [themeType, setThemeType] = useState(mainThemeThree === null ? false : mainThemeThree.organized_theme_type);
    const [updateMainThemeThree] = useMutation(UPDATE_MAIN_THEME_THREE);

    const [summary, setSummary] = useState(!mainThemeThree ? "" : mainThemeThree.summary);
    const [basic, setBasic] = useState(!mainThemeThree || !mainThemeThree.basic ? null : mainThemeThree.basic.id);
    const [sentence, setSentence] = useState(!mainThemeThree || !mainThemeThree.sentence ? null : mainThemeThree.sentence.id);
    const [period, setPeriod] = useState(!mainThemeThree || !mainThemeThree.period ? null : mainThemeThree.period.id);
    const [hybrid, setHybrid] = useState(!mainThemeThree || !mainThemeThree.hybrid ? null : mainThemeThree.hybrid.id);
    const [ternary, setTernary] = useState(!mainThemeThree || !mainThemeThree.ternary ? null : mainThemeThree.ternary.id);

    const [addBasic] = useMutation(ADD_BASIC);
    const [addSentence] = useMutation(ADD_SENTENCE);
    const [addPeriod] = useMutation(ADD_PERIOD);
    const [addHybrid] = useMutation(ADD_HYBRID);
    const [addTernary] = useMutation(ADD_TERNARY);

    const handleMainThemeThreeUpdate = () => {
        updateMainThemeThree({ variables: { id: mainThemeThree.id, organized_theme_type: themeType, summary, basic, sentence, period, hybrid, ternary } });
    }

    useEffect(() => {
        if (mainThemeThree) {
            handleMainThemeThreeUpdate();
        }
    }, [themeType, summary, basic, sentence, period, hybrid, ternary]);

    const handleThemeEnable = () => {
        setThemeType(!themeType);
    };

    const handleBasic = async () => {
        var basicResult = await addBasic();
        setBasic(basicResult.data.addBasic.id);
    };

    const handleSentence = async () => {
        var sentenceResult = await addSentence();
        setSentence(sentenceResult.data.addSentence.id);
    };

    const handlePeriod = async () => {
        var periodResult = await addPeriod();
        setPeriod(periodResult.data.addPeriod.id);
    };

    const handleHybrid = async () => {
        var hybridResult = await addHybrid();
        setHybrid(hybridResult.data.addHybrid.id);
    };

    const handleTernary = async () => {
        var ternaryResult = await addTernary();
        setTernary(ternaryResult.data.addTernary.id);
    };

    return (
        <>
            <ThemeType
                show={mainThree && mainTwo}
                checked={themeType}
                handleChange={handleThemeEnable}
                component={mainThemeThree}
                handleBasic={handleBasic}
                basicId={basic}
                handleSentence={handleSentence}
                sentenceId={sentence}
                handlePeriod={handlePeriod}
                periodId={period}
                handleHybrid={handleHybrid}
                hybridId={hybrid}
                handleTernary={handleTernary}
                ternaryId={ternary}
            />
            <Summary show={mainThree && mainTwo} text={summary} setText={setSummary} />
        </>
    );
}

export default MainThemeThree;
