import { useState } from "react"
import Slide from '@mui/material/Slide'
import ThemeType from "./dropDownComponents/ThemeType";
import Summary from "./Summary";
import SectionEnable from "./SectionEnable";
import { useMutation, useQuery } from "@apollo/client";
import { UPDATE_MOVEMENT } from "../mutations/movementMutations";
import {ADD_MAIN_THEME_ONE} from "../mutations/mainThemeOneMutations";
import { ADD_MAIN_THEME_TWO} from "../mutations/mainThemeTwoMutations";
import { ADD_MAIN_THEME_THREE} from "../mutations/mainThemeThreeMutations";
import {ADD_NESTED_MAIN_THEME} from "../mutations/nestedMainThemeMutations";
import { DELETE_NESTED_MAIN_THEME } from "../mutations/nestedMainThemeMutations";
import LargeScaleMainTheme from "./LargeScaleMainTheme";
import MainThemeTwo from "./MainThemeTwo";
import MainThemeThree from "./MainThemeThree";
import musicNotesDictionary from "./shortHand/ShortHand";

function MovementMainTheme({movement}) {
    console.log(movement);
    const [pitch, setPitch] = useState("");

    const [lsMain, setLsMain] = useState(movement.nested_main_theme === null ? false : true);
    const [mainTwo, setMainTwo] = useState(movement.main_theme_2 === null ? false : true);
    const [mainThree, setMainThree] = useState(movement.main_theme_3 === null ? false : true);

    
    const [updateMovement] = useMutation(UPDATE_MOVEMENT);
    const [addMainOne] = useMutation(ADD_MAIN_THEME_ONE);
    const [addMainTwo] = useMutation(ADD_MAIN_THEME_TWO);
    const [addMainThree] = useMutation(ADD_MAIN_THEME_THREE);
    const [addLsMain] = useMutation(ADD_NESTED_MAIN_THEME);
    const [deleteLsMain] = useMutation(DELETE_NESTED_MAIN_THEME);


    const handleLsMain = async () => {
        if(lsMain === false) {
            var lsMainResult = await addLsMain();
            await updateMovement({variables: {id: movement.id, nested_main_theme:lsMainResult.data.addNestedMainTheme.id}})
        }else{
            deleteLsMain({variables: {id: movement.nested_main_theme.id}})
        }
        setLsMain(!lsMain);
    }
    const handleMainTwo = async () => {
        if(mainTwo === false) {
            var mainTwoResult = await addMainTwo();
            await updateMovement({variables: {id: movement.id, main_theme_2:mainTwoResult.data.addMainThemeTwo.id}})
        }else{
            //delete)
        }
        setMainTwo(!mainTwo);
    }
    const handleMainThree = async () => {
        if(mainThree === false) {
            var mainThreeResult = await addMainThree();
            await updateMovement({variables: {id: movement.id, main_theme_3:mainThreeResult.data.addMainThemeThree.id}})
        }else{
            //delete)
        }
        setMainThree(!mainThree);
    }

    console.log(pitch);
    const handlePitchInput = (event) => {
        let userInput = event.target.value.toLowerCase();
        Object.entries(musicNotesDictionary).forEach(([shorthand, fullNotation]) => {
          const regex = new RegExp(shorthand, "g");
          userInput = userInput.replace(regex, fullNotation);
        });
        setPitch(userInput);
      };
    return (
        <>
            <div className="fs-2 fw-light text-muted mt-5 pt-5">Main Theme One</div>
            <hr className='pt-0 pb-0' />

            <div className="card my-5">
                <div className="card-body">
                    <h5 className="card-title ">Pitch Class Collection</h5>
                    <div className="input-group input-group-lg my-4">
                        <input type="text" className="form-control" placeholder="C" aria-describedby="inputGroup-sizing-lg"></input>
                    </div>
                    <h5 className="card-title mt-5">Central Pitch Class</h5>
                    <div className="input-group input-group-lg my-4">
                        <input type="text" className="form-control" placeholder="C - D♭ - D♯/E♭ - E - F# - G - A - B♭" aria-label="Sizing example input"
                            aria-describedby="inputGroup-sizing-lg" value={pitch} onChange={handlePitchInput}></input>
                    </div>
                </div>
            </div>


            <div className="fs-2 fw-light text-muted mt-5 pt-5">Large Scale Main Theme</div>
            <hr className='pt-0 pb-0' />
            <SectionEnable title={'Large Introduction Main Theme'} show={true} checked={lsMain} handleChange={handleLsMain} />
            <LargeScaleMainTheme nestedMain={movement.nested_main_theme} lsMain={lsMain}/>


            <div className="fs-2 fw-light text-muted mt-5 pt-5">Main Theme Two</div>
            <hr className='pt-0 pb-0' />
            <SectionEnable title={'Second Main Theme'} show={true} checked={mainTwo} handleChange={handleMainTwo} />
            <MainThemeTwo mainThemeTwo={movement.main_theme_2} mainTwo={mainTwo}/>
            

            {/*there is only a third main theme if main theme 2 is enabled*/}
            <Slide direction="up" in={mainTwo} mountOnEnter unmountOnExit>
                <div>
                    <div className="fs-2 fw-light text-muted mt-5 pt-5">Main Theme Three</div>
                    <hr className='pt-0 pb-0' />
                </div>
            </Slide>
            <SectionEnable title={'Third Main Theme'} show={mainTwo} checked={mainThree} handleChange={handleMainThree}/>
            <MainThemeThree mainThemeThree={movement.main_theme_3}  mainTwo={mainTwo} mainThree={mainThree}/>
        </>
    )
}

export default MovementMainTheme