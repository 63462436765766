import { gql } from "@apollo/client";

export const GET_MOFR = gql`
query GET_MOFR($id: ID){
    momentOfFormalRupture(id: $id){
        initial_MOFR_measure,
        initial_MOFR_location,
        has_interpolation,
        num_of_interpolations,
        has_episodes,
        num_of_episodes,
        summary,
    }
}
`
