import { useState, useRef } from "react"
import Slide from '@mui/material/Slide'
import Summary from "./Summary";
import SectionEnable from "./SectionEnable";
import AlteredOptionComponents from "./AlteredOptionComponents";

function Recapitulation() {
    return (
        <>
            <div className="fs-2 fw-light text-muted mt-5 pt-5" id="intro">Recapitulation</div>
            <hr className='pt-0 pb-0' />

            <div className="card my-5 ">
                <div className="card-body">
                    {/* these components are rendered based on what movement components were enabled so some will not be shown */}
                    <AlteredOptionComponents
                        title={'Main Theme One Altered'}
                        checked
                        handlechange
                    />
                    <AlteredOptionComponents
                        title={'Main Theme Two Altered'}
                        checked
                        handleChange
                    />
                    <AlteredOptionComponents
                        title={'Main Theme Three Altered'} 
                        checked
                        handleChange
                        />
                    <AlteredOptionComponents
                        title={'Large Scale Main Theme Altered'}
                        checked
                        handleChange
                         />
                    <AlteredOptionComponents
                        title="Transition one Altered"
                        checked
                        handleChange
                    />
                    <AlteredOptionComponents
                        title="Transition Two Altered"
                        checked
                        handleChange
                    />
                    <AlteredOptionComponents
                        title={'Subordinate Theme  One Altered'}
                        checked
                        handlechange
                    />
                    <AlteredOptionComponents
                        title={'Subordinate Theme  Two Altered'}
                        checked
                        handlechange
                    />
                    <AlteredOptionComponents
                        title={'Subordinate Theme  Three Altered'}
                        checked
                        handlechange
                    />
                    <AlteredOptionComponents
                        title={'Codettas Altered'}
                        checked
                        handlechange
                    />

                </div>
            </div>

            {/*this might need to be updated based on if something is altered or have a summary for each recap option */}
            <Summary show={true}/>
            
        </>
    )
}

export default Recapitulation