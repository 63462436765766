import { useState } from "react"
import Slide from '@mui/material/Slide'
import ThemeType from "./dropDownComponents/ThemeType";
import Summary from "./Summary";
import SectionEnable from "./SectionEnable";
import { useMutation } from "@apollo/client";
import {ADD_TRANSITION_ONE} from "../mutations/transitionOneMutations"
import {ADD_TRANSITION_TWO} from "../mutations/transitionTwoMutations"
import {ADD_NESTED_TRANSITION} from "../mutations/nestedTransitionMutations"
import { UPDATE_MOVEMENT } from "../mutations/movementMutations";
import TransitionOne from "./TransitionOne";
import TransitionTwo from "./TransitionTwo";
import LargeScaleTransition from "./LargeScaleTransition";

function MovementTransition({movement}) {
  const [transitionOne, setTransitionOne] = useState(movement.transition_1 === null ? false : true);
  const [lsTransition, setLsTransition] = useState(movement.nested_transition === null ? false : true);
  const [transitionTwo, setTransitionTwo] = useState(movement.transition_2 === null ? false : true);

  const [addTransOne] = useMutation(ADD_TRANSITION_ONE);
  const [addlsTransition] = useMutation(ADD_NESTED_TRANSITION)
  const [addTransTwo] = useMutation(ADD_TRANSITION_TWO);
  const [updateMovement] = useMutation(UPDATE_MOVEMENT);
  
  const handleTransitionOne = async () => {
    if (transitionOne === false) {
      var transitionOneResult = await addTransOne();
      await updateMovement({ variables: { id: movement.id, transition_1: transitionOneResult.data.addTransitionOne.id } })
  }
  else {
      //delete
  }
    setTransitionOne(!transitionOne);
  }
  const handleLsTransition = async()=>{
    if (lsTransition === false) {
      var lsResult = await addlsTransition();
      await updateMovement({ variables: { id: movement.id, nested_transition: lsResult.data.addNestedTransition.id } })
  }
  else {
      //delete
  }
    setLsTransition(!lsTransition);
  }

  const handleTransitionTwo = async ()=>{
    if (transitionTwo === false) {
      var transitionTwoResult = await addTransTwo();
      await updateMovement({ variables: { id: movement.id, transition_2: transitionTwoResult.data.addTransitionTwo.id } })
  }
  else {
      //delete
  }
    setTransitionTwo(!transitionTwo);
  }

  return (
    <>
      <div className="fs-2 fw-light text-muted mt-5 pt-5" id="intro">Transtion One</div>
      <hr className='pt-0 pb-0' />
      <TransitionOne transOne={movement.transition_1} handleTransitionOne={handleTransitionOne} transitionOne={transitionOne}/>


      <div className="fs-2 fw-light text-muted mt-5 pt-5" id="intro">Large Scale Transition</div>
      <hr className='pt-0 pb-0' />
      <LargeScaleTransition lsTransition={lsTransition} lsTrans={movement.nested_transition} handleLsTransition={handleLsTransition}/>

      <div className="fs-2 fw-light text-muted mt-5 pt-5" id="intro">Transition Two</div>
      <hr className='pt-0 pb-0' />
      <TransitionTwo transTwo ={movement.transition_2} handleTransitionTwo={handleTransitionTwo} transitionTwo={transitionTwo}/>
    </>
  )
}

export default MovementTransition