import { gql } from "@apollo/client";

const ADD_NESTED_TRANSITION = gql`
  mutation ADD_NESTED_TRANSITION(
    $organized_theme_type: Boolean,
    $basic: ID,
    $sentence: ID,
    $period: ID,
    $hybrid: ID,
    $ternary: ID,
    $other: ID,
    $summary: String
  ){
    addNestedTransition(
      organized_theme_type: $organized_theme_type,
      basic: $basic,
      sentence: $sentence,
      period: $period,
      hybrid: $hybrid,
      ternary: $ternary,
      other: $other,
      summary: $summary
    ) {
      id
    }
  }
`;

const UPDATE_NESTED_TRANSITION = gql`
  mutation UPDATE_NESTED_TRANSITION(
    $id: ID!,
    $organized_theme_type: Boolean,
    $basic: ID,
    $sentence: ID,
    $period: ID,
    $hybrid: ID,
    $ternary: ID,
    $other: ID,
    $summary: String
  ){
    updateNestedTransition(
      id: $id,
      organized_theme_type: $organized_theme_type,
      basic: $basic,
      sentence: $sentence,
      period: $period,
      hybrid: $hybrid,
      ternary: $ternary,
      other: $other,
      summary: $summary
    ) {
      id
    }
  }
`;

const DELETE_NESTED_TRANSITION = gql`
  mutation DELETE_NESTED_TRANSITION($id: ID!){
    deleteNestedTransition(id: $id){
      id
    }
  }
`;

export { ADD_NESTED_TRANSITION, UPDATE_NESTED_TRANSITION, DELETE_NESTED_TRANSITION }
