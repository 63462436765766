import FormNavbar from "../components/FormNavbar"
import '../assets/app.css'
import { ADD_PIECE } from "../mutations/pieceMutations"
import { ADD_PIECEOVERVIEW } from "../mutations/pieceOverviewMutations"
import { ADD_DEVELOPMENT } from "../mutations/developmentMutations"
import { ADD_CODA } from "../mutations/codaMutations"
import { ADD_MOFR } from "../mutations/mofrMutations"
import { GET_PIECES } from "../queries/pieceQueries"
import { ADD_MOVEMENT } from "../mutations/movementMutations"
import { useMutation, useQuery } from "@apollo/client"
import decode from 'jwt-decode'
import PieceTable from "../components/PieceTable"

function PieceDashboard() {
  const user = decode(localStorage.getItem('token')).id
  const piece_title = "untitled";
  const last_updated_by = user;
  const created_by = user;
  const deleted = false;
  const first_name = "";
  const last_name = "";
  const composition_year = "";
  const publication_year = "";
  const number_of_movements = 1;
  //const {loading, error, data} = useQuery(GET_PIECES);
  useQuery(GET_PIECES);
  /*const [addPiece] = useMutation(ADD_PIECE, {
    async update(cache, { data: { addPiece } }) {
      try {
        const { pieces } = cache.readQuery({ query: GET_PIECES });

        cache.writeQuery({
          query: GET_PIECES,
          data: { pieces: [...pieces, addPiece] },
        });
      } catch (err) {
        console.log(err);
      }
    },
  })*/
  const [addPiece] = useMutation(ADD_PIECE);
  const [addPieceOverview] = useMutation(ADD_PIECEOVERVIEW);
  const [addCoda] = useMutation(ADD_CODA);
  const [addMOFR] = useMutation(ADD_MOFR);
  const [addDevelopment] = useMutation(ADD_DEVELOPMENT);
  const [addMovement] = useMutation(ADD_MOVEMENT);


  async function initializePiece() {
    var overviewResult = await addPieceOverview({ variables: { composer: { first_name, last_name }, years: { composition_year, publication_year }, number_of_movements } });
    const piece_overview = overviewResult.data.addPieceOverview.id;

    const codaResult = await addCoda({variables: {measures:{start: 1, end:2}}});
    const coda = codaResult.data.addCoda.id

    const mofrResult = await addMOFR();
    const moment_of_formal_rupture = mofrResult.data.addMomentOfFormalRupture.id;

    const developmentResult = await addDevelopment({variables: {measures:{start: 1, end:2}}});
    const development = developmentResult.data.addDevelopment.id

    const movementsResult = await addMovement({variables: {title: "Movement one"}});
    const movement = movementsResult.data.addMovement.id;

  
    var { data } = await addPiece({ variables: { piece_title, last_updated_by, created_by, piece_overview, coda, moment_of_formal_rupture, development, movements:[movement] } } );
    console.log(data);
    const id = data.addPiece.id;
    window.location.href = `/Forms/${id}`
  }


  return (
    <>
      <div className="container">
        <FormNavbar />
      </div>
      <div className="container-fluid menu-jumbo">
        <div className="container py-5">
          <button className="btn btn-primary btn-lg" onClick={initializePiece}>Add New Piece</button>
        </div>
      </div>
      <div className="container py-5">
          <PieceTable/>
        </div>
    </>
  )
}
//href={`/Forms/${piece.id}`}
export default PieceDashboard