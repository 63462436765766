import { gql } from "@apollo/client";
const ADD_PERIOD = gql`
mutation ADD_PERIOD($basic_idea_1: BasicIdeaInput, 
                $basic_idea_2: BasicIdeaInput,
                $contrasting_idea_option_1: String,
                $contrasting_idea_option_2: String,
                $terminus: TerminusInput,
                $winding_down_technique: WindingDownTechniqueInput,
                ){
                    addPeriod(basic_idea_1: $basic_idea_1,
                        basic_idea_2: $basic_idea_2,
                        contrasting_idea_option_1: $contrasting_idea_option_1,
                        contrasting_idea_option_2: $contrasting_idea_option_2,
                        terminus: $terminus,
                        winding_down_technique: $winding_down_technique){
                            id
                        }
                }
`

const UPDATE_PERIOD = gql`
mutation UPDATE_PERIOD($id: ID!,
                $basic_idea_1: BasicIdeaInput, 
                $basic_idea_2: BasicIdeaInput,
                $contrasting_idea_option_1: String,
                $constrasting_idea_option_2: String,
                $terminus: TerminusInput,
                $winding_down_technique: WindingDownTechniqueInput,
                ){
                    updatePeriod(id: $id,
                        basic_idea_1: $basic_idea_1,
                        basic_idea_2: $basic_idea_2,
                        contrasting_idea_option_1: $contrasting_idea_option_1,
                        terminus: $terminus,
                        winding_down_technique: $winding_down_technique){
                            id
                        }
                }
`

const DELETE_PERIOD = gql `
mutation DELETE_PERIOD($id: ID!){
    deletePeriod(id: $id){
        id
    }
}
`

export {ADD_PERIOD, UPDATE_PERIOD, DELETE_PERIOD}