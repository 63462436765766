import Slide from '@mui/material/Slide';
import { useState, useEffect } from 'react';
import Basic from "../themeTypeComponents/Basic";
import Sentence from "../themeTypeComponents/Sentence";
import Period from "../themeTypeComponents/Period";
import Ternary from "../themeTypeComponents/Ternary";
import Other from "../themeTypeComponents/Other";
import Hybrid from "../themeTypeComponents/Hybrid";
import { DELETE_BASIC } from '../../mutations/basicMutations';
import { DELETE_SENTENCE } from '../../mutations/sentenceMutations';
import { DELETE_PERIOD } from '../../mutations/periodMutations';
import { DELETE_HYBRID } from '../../mutations/hybridMutations';
import { DELETE_TERNARY } from '../../mutations/ternaryMutations';
import { useMutation } from '@apollo/client';

function ThemeType({ show, checked, handleChange, component,
    handleBasic, basicId, handleSentence, sentenceId, handlePeriod, periodId,
    handleHybrid, hybridId, handleTernary, ternaryId,}) {
    // get basic id then send to the basic etc for update functions 
    //console.log(component);

    const [themeClass, setThemeClass] = useState(checked === true ? "nav-link dropdown-toggle fs-5" : "nav-link dropdown-toggle fs-5 not-allowed");
    const [basic, setBasic] = useState(component == null || component.basic === null ? false : true);
    const [sentence, setSentence] = useState(component == null || component.sentence === null ? false : true);
    const [period, setPeriod] = useState(component == null || component.period === null ? false : true);
    const [hybrid, setHybrid] = useState(component == null || component.hybrid === null ? false : true);
    const [ternary, setTernary] = useState(component == null || component.ternary === null ? false : true);
    const [other, setOther] = useState(component == null || component.other === null ? false : true);

    const [deleteBasic] = useMutation(DELETE_BASIC);
    const [deleteSentence] = useMutation(DELETE_SENTENCE);
    const [deletePeriod] = useMutation(DELETE_PERIOD);
    const [deleteHybrid] = useMutation(DELETE_HYBRID);
    const [deleteTernary] = useMutation(DELETE_TERNARY);
    useEffect(() => {
        var c = checked === true ? "nav-link dropdown-toggle fs-5" : "nav-link dropdown-toggle fs-5 not-allowed";
        setThemeClass(c);
    }, [checked])

    const setMultStates = () => {
        if (basic === true) deleteBasic({ variables: { id: basicId } })
        if (sentence === true) deleteSentence({ variables: { id: sentenceId } })
        if (period === true) deletePeriod({ variables: { id: periodId } })
        if(hybrid === true) deleteHybrid({ variables: { id:hybridId}})
        if(ternary === true) deleteTernary({variables: {id: ternaryId}})
        setBasic(false);
        setSentence(false);
        setPeriod(false);
        setHybrid(false);
        setTernary(false);
        setOther(false);


    }
    const handleOption = (option) => {
        var state;
        switch (option) {
            case "basic":

            if (basic) {
                deleteBasic({ variables: { id: basicId } });
                setBasic(false);
            } else {
                setMultStates();
                handleBasic();
                setBasic(true);
            }
            break;

            case "sentence":
                if (sentence) {
                    deleteSentence({ variables: { id: sentenceId } });
                    setSentence(false);
                } else {
                    setMultStates();
                    handleSentence();
                    setSentence(true);
                }
                break;
            case "period":
                if (period) {
                    deletePeriod({ variables: { id: periodId } });
                    setPeriod(false);
                } else {
                    setMultStates();
                    handlePeriod();
                    setPeriod(true);
                }
                break;
            case "hybrid":
                if (hybrid) {
                    deleteHybrid({ variables: { id: hybridId } });
                    setHybrid(false);
                } else {
                    setMultStates();
                    handleHybrid();
                    setHybrid(true);
                }
                break;
            case "ternary":
                if (ternary) {
                    deleteTernary({ variables: { id: ternaryId } });
                    setTernary(false);
                } else {
                    setMultStates();
                    handleTernary();
                    setTernary(true);
                }
                break;
            case "other":
                state = other == true ? false : true;
                if (state != false) {
                    setMultStates();
                }
                setOther(state);
                break;
        }
    }
    return (
        <>
            <Slide direction="up" in={show} mountOnEnter unmountOnExit>
                <div className="card my-5 option">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-10">
                                <div className="dropdown">
                                    <a className={themeClass} disabled={!checked} id="navbarDarkDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        Theme Type
                                    </a>
                                    <ul className="dropdown-menu dropdown-menu-light dropMenu " aria-labelledby="dropdownMenuButton2">
                                        <li><button className="dropdown-item" onClick={() => { handleOption("basic") }}>BasicType</button></li>
                                        <li><button className="dropdown-item" onClick={() => { handleOption("sentence") }}>Sentence</button></li>
                                        <li><button className="dropdown-item" onClick={() => { handleOption("period") }}>Period</button></li>
                                        <li><button className="dropdown-item" onClick={() => { handleOption("hybrid") }}>Hybrid</button></li>
                                        <li><button className="dropdown-item" onClick={() => { handleOption("ternary") }}>Ternary</button></li>
                                        <li><hr className="dropdown-divider" /></li>
                                        <li><button className="dropdown-item" onClick={() => { handleOption("other") }}>Other</button></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-2 d-flex justify-content-end">
                                <div className="form-check form-switch">
                                    <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" checked={checked} onChange={handleChange}></input>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </Slide>
            <Basic basic={basic} intro={show} />
            <Sentence sentence={sentence} intro={show} />
            <Hybrid hybrid={hybrid} intro={show} />
            <Period period={period} intro={show} />
            <Ternary ternary={ternary} intro={show}></Ternary>
            <Other other={other} intro={show} />
        </>
    )
}

export default ThemeType