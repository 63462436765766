import { gql} from '@apollo/client'

export const GET_USERS = gql`
query GET_USERS($limit: Int, $offset: Int){
    users(limit: $limit, offset: $offset){
    id
    email
    lastName
    firstName
    role
    deleted
  }
}
`;

