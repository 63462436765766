import { gql } from "@apollo/client";

const ADD_TRANSITION_ONE = gql`
mutation ADD_TRANSITION_ONE($pitch_class_collection: String,
    $central_pitch_class: String,
    $organized_theme_type: Boolean,
    $basic: ID,
    $sentence: ID,
    $period: ID,
    $hybrid: ID,
    $ternary: ID,
    $other: ID,
    $summary: String
  ){
    addTransitionOne(pitch_class_collection: $pitch_class_collection,
      central_pitch_class: $central_pitch_class,
      organized_theme_type: $organized_theme_type,
      basic: $basic,
      sentence: $sentence,
      period: $period,
      hybrid: $hybrid,
      ternary: $ternary,
      other: $other,
      summary: $summary
    ) {
      id
    }
  }
`;

const UPDATE_TRANSITION_ONE = gql`
  mutation UPDATE_TRANSITION_ONE($id: ID!,
    $pitch_class_collection: String,
    $central_pitch_class: String,
    $organized_theme_type: Boolean,
    $basic: ID,
    $sentence: ID,
    $period: ID,
    $hybrid: ID,
    $ternary: ID,
    $other: ID,
    $summary: String
  ){
    updateTransitionOne(id: $id,
      pitch_class_collection: $pitch_class_collection,
      central_pitch_class: $central_pitch_class,
      organized_theme_type: $organized_theme_type,
      basic: $basic,
      sentence: $sentence,
      period: $period,
      hybrid: $hybrid,
      ternary: $ternary,
      other: $other,
      summary: $summary
    ) {
      id
    }
  }
`;

const DELETE_TRANSITION_ONE = gql `
  mutation DELETE_TRANSITION_ONE($id: ID!){
    deleteTransitionOne(id: $id){
      id
    }
  }
`;

export { ADD_TRANSITION_ONE, UPDATE_TRANSITION_ONE, DELETE_TRANSITION_ONE }
