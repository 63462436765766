import { gql } from "@apollo/client";

const ADD_NESTED_INTRO = gql`
mutation ADD_NESTED_INTRO($organized_theme_type: Boolean,
                    $basic: ID,
                    $sentence: ID,
                    $period: ID
                    $hybrid: ID,
                    $ternary: ID,
                    $other: ID,
                    $summary: String){
                        addNestedIntro(organized_theme_type: $organized_theme_type,
                            basic: $basic,
                            sentence: $sentence,
                            period: $period,
                            hybrid: $hybrid,
                            ternary: $ternary,
                            other: $other,
                            summary: $summary){
                                id
                                organized_theme_type
                                basic{id}
                                sentence{id}
                                period{id}
                                ternary{id}
                                hybrid{id}
                                other{id}
                                summary
                            }
                    }`

const UPDATE_NESTED_INTRO = gql`
mutation UPDATE_NESTED_INTRO($id: ID!,
                    $organized_theme_type: Boolean,
                    $basic: ID,
                    $sentence: ID,
                    $period: ID
                    $hybrid: ID,
                    $ternary: ID,
                    $other: ID,
                    $summary: String,){
                        updateNestedIntro(
                            id: $id,
                            organized_theme_type: $organized_theme_type,
                            basic: $basic,
                            sentence: $sentence,
                            period: $period,
                            hybrid: $hybrid,
                            ternary: $ternary,
                            other: $other,
                            summary: $summary){
                                id
                                organized_theme_type
                                basic{id}
                                sentence{id}
                                period{id}
                                ternary{id}
                                hybrid{id}
                                other{id}
                                summary
                            }
                    }`
export { ADD_NESTED_INTRO, UPDATE_NESTED_INTRO }