import { DataGrid, renderActionsCell } from '@mui/x-data-grid';
import { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { GET_PAGINATED_PIECES } from '../queries/pieceQueries';
import fileImg from '../assets/file-storage.png';
import LinearProgress  from '@mui/material/LinearProgress';


const columns = [
    {
        field: 'folderIcons', headerName: '', width: 50, disableColumnMenu: true,
        renderCell: (p) => (
            <img src={fileImg} style={{ height: 25 }}></img>
        )
    },
    { field: 'piece_title', headerName: 'Piece Title', width: 680 },
    {
        field: 'last_updated_by', headerName: 'Last Updated By', width: 180,
        valueGetter: (p) => {
            return p.row.last_updated_by.firstName + " " + p.row.last_updated_by.lastName
        }
    },
    {
        field: 'createdAt', headerName: 'Created At', width: 230,
        valueGetter: (p) => {
            var date = new Date(parseInt(p.row.createdAt));
            return date.toDateString();
        }
    },
    {
        field: 'action', headerName: '', sortable: false, width: 50, disableColumnMenu: true,
        renderCell: (p) => (
            <span className="material-symbols-outlined">
                more_vert
            </span>
        )
    }
];


function PieceTable() {
    const [selectedRowName, setSelectedRowName] = useState('');
    const [page, setPage] = useState(1);
    const [pieces, setPieces] = useState([]);
    const [totalPieces, setTotalPieces] = useState(0);
    const [prev, setPrev] = useState("page-item append-link disabled");
    const [next, setNext] = useState("page-item append-link");

    //paginated Query
    const { loading, error, data } = useQuery(GET_PAGINATED_PIECES, {
        variables: {
            limit: 10,
            offset: page > 1 ? (page * 10) - 10 : 0        //calculate the offset
        }
    });
    const handleRowClick = (p) => {
        //setSelectedRowName(p.row.id);
        window.location.href = `/Forms/${p.row.id}`
    }

    useEffect(() => {
        //set states once the data has been loaded in
        if (data) {
            setPieces(data.paginatedPieces)
        }
    }, [data])

    /*  const handleSearch = (e) => {
          const term = e.target.value.toLowerCase();
          const filteredRows = initialRows.filter(
              (row) =>
                  row.name.toLowerCase().includes(term) ||
                  row.author.toLowerCase().includes(term) ||
                  row.date.toLowerCase().includes(term)
          );
          setSearchTerm(term);
          setRows(filteredRows);
      };
  */
    const handlePrev = () => {
        //check if reached the beginning of the page
        console.log(page)
        if (page > 1) {
            setPage(page - 1)
            setPrev("page-item append-link")
            setNext("page-item append-link")
        }
        else {
            setPrev("page-item append-link disabled");
        }
        console.log("prev: " + page);
    }

    const handleNext = () => {
        //check if reached the end of the page
        console.log(data.paginatedPieces.length);
        if (data.paginatedPieces.length >= 10) {
            setPage(page + 1);
            setPrev("page-item append-link");
            setNext("page-item append-link")
        }
        else {
            setNext("page-item append-link disabled")
            setPrev("page-item append-link");
        }

        console.log("next: " + page);

    }
    return (
        <>
            <div style={{ height: 400, width: '100%' }}>
                <DataGrid
                  slots={{
                    loadingOverlay: LinearProgress,
                  }}
                  loading={loading}
                    rows={pieces}
                    columns={columns}
                    onRowClick={handleRowClick}
                    pageSize={10}
                    hideFooter
                />
            </div>
            <nav className='py-3'>
                <ul className="pagination justify-content-end" id="pagination">
                    <li className={prev}><button className="page-link" onClick={handlePrev}>Previous</button></li>
                    <li className={next}><button className="page-link" onClick={handleNext}>Next</button></li>
                </ul>
            </nav>
            {selectedRowName}
        </>
    )
}

export default PieceTable