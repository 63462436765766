
    const musicNotesDictionary = {
        "c": "C",
        "c#": "C♯",
        "db": "D♭",
        "d": "D",
        "d#": "D♯",
        "eb": "E♭",
        "e": "E",
        "f": "F",
        "f#": "F♯",
        "gb": "G♭",
        "g": "G",
        "g#": "G♯",
        "ab": "A♭",
        "a": "A",
        "a#": "A♯",
        "bb": "B♭",
        "b": "B",
      };
      

   

export default musicNotesDictionary;