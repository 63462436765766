import { Slide } from "@mui/material"
import SectionEnable from "./SectionEnable"
import ThemeType from "./dropDownComponents/ThemeType"
import Summary from "./Summary"
import { useState, useEffect } from "react"
import { useMutation } from '@apollo/client';
import { ADD_BASIC } from '../mutations/basicMutations';
import { ADD_SENTENCE } from '../mutations/sentenceMutations';
import { ADD_PERIOD } from '../mutations/periodMutations';
import { ADD_HYBRID } from '../mutations/hybridMutations';
import { ADD_TERNARY } from '../mutations/ternaryMutations';
import { UPDATE_NESTED_INTRO } from "../mutations/nestedIntroMutations";

function LargeScaleIntroduction({intro, lsIntro, handleLsIntro, nestedIntro}) {
    //console.log(nestedIntro);
    const [updateLsIntro] = useMutation(UPDATE_NESTED_INTRO);
    const [themeType, setThemeType] = useState(nestedIntro === null ? false : (nestedIntro.organized_theme_type));

    const [summary, setSummary] = useState(!nestedIntro ? "" : nestedIntro.summary)
    const [basic, setBasic] = useState(!nestedIntro || !nestedIntro.basic ? null : nestedIntro.basic.id);
    const [sentence, setSentence] = useState(!nestedIntro || !nestedIntro.sentence ? null : nestedIntro.sentence.id);
    const [period, setPeriod] = useState(!nestedIntro || !nestedIntro.period ? null : nestedIntro.period.id);
    const [hybrid, setHybrid] = useState(!nestedIntro || !nestedIntro.hybrid ? null : nestedIntro.hybrid.id);
    const [ternary, setTernary] = useState(!nestedIntro || !nestedIntro.ternary ? null : nestedIntro.ternary.id);

    const [addBasic] = useMutation(ADD_BASIC);
    const [addSentence] = useMutation(ADD_SENTENCE);
    const [addPeriod] = useMutation(ADD_PERIOD);
    const [addHybrid] = useMutation(ADD_HYBRID);
    const [addTernary] = useMutation(ADD_TERNARY);

    const handleLsIntroUpdate = ()=>{
        updateLsIntro({variables: {id: nestedIntro.id, organized_theme_type: themeType, summary, basic, sentence, period, hybrid, ternary}})
    }

    const handleThemeEnable = () => {
        setThemeType(!themeType);

    }
    const handleBasic = async () => {
        var basicResult = await addBasic();
        setBasic(basicResult.data.addBasic.id);
    };
    const handleSentence = async () => {
        var sentenceResult = await addSentence();
        setSentence(sentenceResult.data.addSentence.id)
    }
    const handlePeriod = async () => {
        var periodResult = await addPeriod();
        setPeriod(periodResult.data.addPeriod.id);
    }
    const handleHybrid = async () => {
        var hybridResult = await addHybrid();
        setHybrid(hybridResult.data.addHybrid.id);
    }
    const handleTernary = async () => {
        var ternaryResult = await addTernary();
        setTernary(ternaryResult.data.addTernary.id);
        console.log(ternaryResult.data.addTernary);

    }
    
    useEffect(() => {
        if (nestedIntro) {
            handleLsIntroUpdate();
        }
    }, [themeType, summary, basic, sentence, period, hybrid, ternary]);
  return (
    <>
    <Slide direction="up" in={intro} mountOnEnter unmountOnExit>
                <div>
                    <div className="fs-2 fw-light text-muted mt-5 pt-5" id="lsintro">Large-Scale Introduction</div>
                    <hr className='pt-0 pb-0' />
                </div>
            </Slide>
            <SectionEnable title={'Large-Scale Introduction'} show={intro} checked={lsIntro} handleChange={handleLsIntro} />
            <ThemeType show={intro && lsIntro} checked={themeType} handleChange={handleThemeEnable} component={nestedIntro}
                handleBasic={handleBasic} basicId={basic}
                handleSentence={handleSentence} sentenceId={sentence}
                handlePeriod={handlePeriod} periodId={period}
                handleHybrid={handleHybrid} hybridId={hybrid}
                handleTernary={handleTernary} ternaryId={ternary} 
            />
            <Summary show={intro && lsIntro}  text={summary} setText={setSummary}  />
    </>
  )
}

export default LargeScaleIntroduction