import CreateUser from '../components/CreateUser';
import axios from 'axios';
import { useQuery } from '@apollo/client'
import { useEffect, useState } from 'react';
import { GET_USERS } from '../queries/userQueries';
import LinearProgress from '@mui/material/LinearProgress';
import UserTable from '../components/UserTable';
import { useNavigate } from 'react-router-dom';
import {Select, Search} from '../assets/Users';
import '../assets/app.css';
function Users() {

    const navigate = useNavigate();
    const [page, setPage] = useState(0);
    const [prev, setPrev] = useState("page-item append-link disabled");
    const [searchQuery, setSearchQuery] = useState("");
    var p = 0;
    async function promisedToken() {
        var res = await axios.get('https://codetta-back-end.vercel.app/auth', { 'headers': { 'x-access-token': localStorage.getItem("token") } })
        //'http://localhost:4000/auth' || 
        //console.log(res);
        if (!res.data.isLoggedIn) {
            navigate('/login');
        }
    }
    useEffect(() => {
        promisedToken();
    }, []);
    const PAGE_SIZE = 10;
    const { loading, error, data } = useQuery(GET_USERS,{
        variables:{
            limit: PAGE_SIZE,
            offset: page * PAGE_SIZE,
        }
    });
    function loadMore(){
        setPage(p + 1);
    }
    if (loading) return (<LinearProgress loading="true" />);
    if (error) return (<p>error</p>);
    console.log(error);
   
    return (
        <>
            <CreateUser />
            <div className="container mt-4">
                <div className="row justify-content-end">
                    <div className="col-3">
                        <input className="form-control" type="search" onInput={(e)=>{setSearchQuery(e.target.value); Search(e.target.value)}} placeholder="Search" aria-label="Search"></input>
                    </div>
                </div>
                <div className="table-responsive-md">
                    <table className="table caption-top ">
                        <caption>List of users</caption>
                        <thead>
                            <tr>
                                <th scope="col">First</th>
                                <th scope="col">Last</th>
                                <th scope="col">Email</th>
                                <th scope="col">Role</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody id="dataBody">
                        {data.users.map((user) => (<UserTable key={user.id} user={user} />))}
                        </tbody>
                    </table>
                </div>
                <nav aria-label="Page navigation example">
                    <ul className="pagination justify-content-end" id="pagination">
                        <li className={prev}><button className="page-link" onClick={()=>{setPage(page - 1); if(page === 1)setPrev("page-item append-link disabled")}}>Previous</button></li>
                        <li className="page-item append-link"><button className="page-link" onClick={()=>{setPage(page + 1); setPrev("page-item append-link")}}>Next</button></li>
                    </ul>
                </nav>
            </div>
        </>
    )
}

export default Users
