import { gql } from "@apollo/client";

const GET_PIECEOVERVIEW = gql`
query GET_PIECEOVERVIEW($id: ID!){
    pieceOverview(id: $id){
        id
        composer{first_name, last_name}
        years{composition_year, publication_year}
        number_of_movements
    }
}
`

export { GET_PIECEOVERVIEW }