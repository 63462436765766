import ThemeType from "./dropDownComponents/ThemeType"
import Summary from "./Summary"
import { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import SectionEnable from "./SectionEnable"
import { UPDATE_NESTED_TRANSITION } from "../mutations/nestedTransitionMutations";
import { ADD_BASIC } from '../mutations/basicMutations';
import { ADD_SENTENCE } from '../mutations/sentenceMutations';
import { ADD_PERIOD } from '../mutations/periodMutations';
import { ADD_HYBRID } from '../mutations/hybridMutations';
import { ADD_TERNARY } from '../mutations/ternaryMutations';

function LargeScaleTransition({ lsTrans, lsTransition, handleLsTransition }) {

    const [summary, setSummary] = useState(!lsTrans ? "" : lsTrans.summary)
    const [basic, setBasic] = useState(!lsTrans || !lsTrans.basic ? null : lsTrans.basic.id);
    const [sentence, setSentence] = useState(!lsTrans || !lsTrans.sentence ? null : lsTrans.sentence.id);
    const [period, setPeriod] = useState(!lsTrans || !lsTrans.period ? null : lsTrans.period.id);
    const [hybrid, setHybrid] = useState(!lsTrans || !lsTrans.hybrid ? null : lsTrans.hybrid.id);
    const [ternary, setTernary] = useState(!lsTrans || !lsTrans.ternary ? null : lsTrans.ternary.id);

    const [themeType, setThemeType] = useState(lsTrans === null ? false : (lsTrans.organized_theme_type));
    const [updateLsTrans] = useMutation(UPDATE_NESTED_TRANSITION)

    const [addBasic] = useMutation(ADD_BASIC);
    const [addSentence] = useMutation(ADD_SENTENCE);
    const [addPeriod] = useMutation(ADD_PERIOD);
    const [addHybrid] = useMutation(ADD_HYBRID);
    const [addTernary] = useMutation(ADD_TERNARY);

    const handleLsUpdate = () => {
        updateLsTrans({ variables: { id: lsTrans.id, organized_theme_type: themeType, summary, basic, sentence, period, hybrid, ternary } })
        //console.log('start: ' + start + ' end: ' + end)
    }
    //console.log('is theme enabled: ' + themeType);

    useEffect(() => {
        if (lsTrans) {
            handleLsUpdate();
        }
    }, [themeType, summary, basic, sentence, period, hybrid, ternary]);


    const handleThemeEnable = () => {
        setThemeType(!themeType);

    }
    const handleBasic = async () => {
        var basicResult = await addBasic();
        setBasic(basicResult.data.addBasic.id);
    };
    const handleSentence = async () => {
        var sentenceResult = await addSentence();
        setSentence(sentenceResult.data.addSentence.id)
    }
    const handlePeriod = async () => {
        var periodResult = await addPeriod();
        setPeriod(periodResult.data.addPeriod.id);
    }
    const handleHybrid = async () => {
        var hybridResult = await addHybrid();
        setHybrid(hybridResult.data.addHybrid.id);
    }
    const handleTernary = async () => {
        var ternaryResult = await addTernary();
        setTernary(ternaryResult.data.addTernary.id);
    }


    const handleOther = () => {

    }

    return (
        <div>
            <SectionEnable title={'Large-Scale Introduction'} show={true} checked={lsTransition} handleChange={handleLsTransition} />
            <ThemeType show={lsTransition} checked={themeType} handleChange={handleThemeEnable} component={lsTrans}
                handleBasic={handleBasic} basicId={basic}
                handleSentence={handleSentence} sentenceId={sentence}
                handlePeriod={handlePeriod} periodId={period}
                handleHybrid={handleHybrid} hybridId={hybrid}
                handleTernary={handleTernary} ternaryId={ternary}
            />
            <Summary show={lsTransition} text={summary} setText={setSummary} />
        </div>
    )
}

export default LargeScaleTransition