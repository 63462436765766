import { useState, useEffect } from "react"
import { useMutation, useQuery } from "@apollo/client"
import { UPDATE_PIECEOVERVIEW } from "../mutations/pieceOverviewMutations"
import { GET_PIECEOVERVIEW } from "../queries/pieceOverviewQueries"
import { Slider } from "@mui/material"

function PieceOverview({ id }) {
    const overviewQueries = useQuery(GET_PIECEOVERVIEW, { variables: { id: id } })
    const [updateOverview] = useMutation(UPDATE_PIECEOVERVIEW);

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [compositionYear, setCompositionYear] = useState("");
    const [publicationYear, setPublicationYear] = useState("");
    const [numOfMovements, setNumOfMovements] = useState(-1);
    //we use the loading effect to set the states of the use state variables once the data is actually loaded 
    //set values once the promises from the GET Queries have been resolved 
    //he useEffect has a dependency array [overviewQueries.data], which means it will only run when overviewQueries.data changes. This ensures that the state is updated whenever the query data changes
    useEffect(() => {
        if (overviewQueries.data && overviewQueries.data.pieceOverview) {
            const { composer, years, number_of_movements } = overviewQueries.data.pieceOverview;
            setFirstName(composer.first_name);
            setLastName(composer.last_name);
            setCompositionYear(years.composition_year)
            setPublicationYear(years.publication_year)
            setNumOfMovements(number_of_movements)
        }
    }, [overviewQueries.data])

    //update user input functions
    const handleOverview = () => {
        updateOverview({
            variables:
            {
                id: id,
                composer: { first_name: firstName, last_name: lastName },
                years: { composition_year: compositionYear, publication_year: publicationYear },
                number_of_movements: numOfMovements
            },
        })
    };
    useEffect(() => {
        //update the database 10 seconds after user input
        const intervalId = setInterval(handleOverview, 30000);

        // Cleanup the interval on unmount
        return () => clearInterval(intervalId);
    }, [firstName, lastName, compositionYear, publicationYear, numOfMovements]);

    const handleFirstName = (e) => {
        setFirstName(e);
    }
    const handleLastName = (e) => {
        setLastName(e);
    }
    const handlePubYear = (e) => {
        setPublicationYear(e);
    }
    const handleCompYear = (e) => {
        setCompositionYear(e);
    }
    const handleNumOfMov = (e) => {
        setNumOfMovements(e);
    }
    const handleInputChange = (event) => {
        const value = event.target.value;
        // Ensure the value is within the range 0-10
        if (value >= 0 && value <= 10) {
            setNumOfMovements(Number(value));
        }
    };
    const handleSliderChange = (event, newValue) => {
        setNumOfMovements(Number(newValue));
    };
    return (
        <>
            <div className="card">
                <div className="card-body">
                    <h5 className="card-title ">Composer</h5>
                    <div className="input-group input-group-lg my-4">
                        <input type="text" className="form-control" placeholder="First Name" value={firstName} onChange={((e) => { handleFirstName(e.target.value) })}
                            aria-label="Sizing example input" aria-describedby="inputGroup-sizing-lg"></input>
                    </div>
                    <div className="input-group input-group-lg my-4">
                        <input type="text" className="form-control" placeholder="Last Name" value={lastName} onChange={((e) => { handleLastName(e.target.value) })}
                            aria-label="Sizing example input" aria-describedby="inputGroup-sizing-lg"></input>
                    </div>
                </div>
            </div>
            <div className="card my-5">
                <div className="card-body">
                    <h5 className="card-title ">Composition Year</h5>
                    <div className="input-group input-group-lg my-4">
                        <input type="text" className="form-control" placeholder="" value={compositionYear == "" ? "" : compositionYear} aria-label="Sizing example input" onChange={(e) => { handleCompYear(e.target.value) }}
                            aria-describedby="inputGroup-sizing-lg"></input>
                    </div>
                    <h5 className="card-title mt-5">Publication Year</h5>
                    <div className="input-group input-group-lg my-4">
                        <input type="text" className="form-control" placeholder="" aria-label="Sizing example input" value={publicationYear == "" ? "" : publicationYear} onChange={(e) => { handlePubYear(e.target.value) }}
                            aria-describedby="inputGroup-sizing-lg"></input>
                    </div>
                </div>
            </div>
            <div className="card">
                <div className="card-body">
                    <h5 className="card-title ">Number of Movements</h5>
                    <div className="row mt-4 mb-3">
                        <div className="col-md-10 ms-2">
                            <Slider
                                value={numOfMovements}
                                onChange={handleSliderChange}
                                min={0}
                                max={10}
                                color="primary"
                                valueLabelDisplay="auto"
                            />
                        </div>
                        <div className="col-md-1">
                            <input
                                className='form-control px-0 text-center'
                                value={numOfMovements}
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PieceOverview