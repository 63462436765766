import { gql } from "@apollo/client";

const ADD_CODA = gql`
mutation addCoda(
    $measures: MeasuresInput,
    $pitch_class_collection: String, 
    $central_pitch_class: String,
    $summary: String,
){
    addCoda(measures: $measures,
    pitch_class_collection: $pitch_class_collection,
    central_pitch_class: $central_pitch_class,
    summary: $summary
    ){
        id
        measures{start, end}
        pitch_class_collection
        summary
        central_pitch_class 
    }
}
`

export{ ADD_CODA};