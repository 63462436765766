import { gql } from "@apollo/client";
const ADD_PIECE = gql`
mutation ADD_PIECE($piece_title: String,
            $last_updated_by: ID,
            $created_by: ID,
            $piece_overview: ID!,
            $movements: [ID],
            $moment_of_formal_rupture: ID,
            $development: ID,
            $recapitulation: ID,
            $coda: ID,
            ){
                addPiece(
                    piece_title: $piece_title,
                    last_updated_by:$last_updated_by,
                    created_by: $created_by,
                    piece_overview: $piece_overview,
                    movements: $movements,
                    moment_of_formal_rupture: $moment_of_formal_rupture,
                    development: $development,
                    recapitulation: $recapitulation,
                    coda: $coda,
                ){
                    id
                    piece_title
                    last_updated_by{
                        id
                    }
                    created_by{
                        id
                    }
                    piece_overview{
                        id
                    }
                    movements{
                        id
                        title
                    }
                    moment_of_formal_rupture{
                        id
                    }
                    coda{
                        id
                    }
                    development{
                        id
                    }
                    deleted
                }
            }
`;

const UPDATE_PIECE = gql`
mutation UPDATE_PIECE ($id: ID!,
         $piece_title: String, 
         $last_updated_by: String, 
         $deleted: Boolean,
         $movements: [ID],
         ){
        updatePiece(id: $id, 
            piece_title: $piece_title, 
            last_updated_by: $last_updated_by, 
            movements: $movements,
            deleted: $deleted
        ){
            id
            piece_title
            last_updated_by{
                id
            }
            movements{
                id
                title
            }
            deleted
    }
}
`

export { ADD_PIECE, UPDATE_PIECE }