import { Slide } from "@mui/material"
import SectionEnable from "./SectionEnable"
import ThemeType from "./dropDownComponents/ThemeType"
import Summary from "./Summary"
import { useState, useEffect } from "react"
import { useMutation } from '@apollo/client';
import { ADD_BASIC } from '../mutations/basicMutations';
import { ADD_SENTENCE } from '../mutations/sentenceMutations';
import { ADD_PERIOD } from '../mutations/periodMutations';
import { ADD_HYBRID } from '../mutations/hybridMutations';
import { ADD_TERNARY } from '../mutations/ternaryMutations';
import { UPDATE_INTRODUCTION_TWO } from "../mutations/introductionTwoMutations"

function IntroductionTwo({intro, secondIntro, introTwo, handleSecondIntro}) {

    const [updateIntroTwo] = useMutation(UPDATE_INTRODUCTION_TWO);
    const [themeType, setThemeType] = useState(introTwo === null ? false : (introTwo.organized_theme_type));

    const [summary, setSummary] = useState(!introTwo ? "" : introTwo.summary)
    const [basic, setBasic] = useState(!introTwo || !introTwo.basic ? null : introTwo.basic.id);
    const [sentence, setSentence] = useState(!introTwo || !introTwo.sentence ? null : introTwo.sentence.id);
    const [period, setPeriod] = useState(!introTwo || !introTwo.period ? null : introTwo.period.id);
    const [hybrid, setHybrid] = useState(!introTwo || !introTwo.hybrid ? null : introTwo.hybrid.id);
    const [ternary, setTernary] = useState(!introTwo || !introTwo.ternary ? null : introTwo.ternary.id);

    const [addBasic] = useMutation(ADD_BASIC);
    const [addSentence] = useMutation(ADD_SENTENCE);
    const [addPeriod] = useMutation(ADD_PERIOD);
    const [addHybrid] = useMutation(ADD_HYBRID);
    const [addTernary] = useMutation(ADD_TERNARY);

    const handleSecondIntroUpdate = ()=>{
        updateIntroTwo({variables: {id: introTwo.id, organized_theme_type: themeType, summary, basic, sentence, period, hybrid, ternary}})
    }

    const handleThemeEnable = () => {
        setThemeType(!themeType);

    }
    const handleBasic = async () => {
        var basicResult = await addBasic();
        setBasic(basicResult.data.addBasic.id);
    };
    const handleSentence = async () => {
        var sentenceResult = await addSentence();
        setSentence(sentenceResult.data.addSentence.id)
    }
    const handlePeriod = async () => {
        var periodResult = await addPeriod();
        setPeriod(periodResult.data.addPeriod.id);
    }
    const handleHybrid = async () => {
        var hybridResult = await addHybrid();
        setHybrid(hybridResult.data.addHybrid.id);
    }
    const handleTernary = async () => {
        var ternaryResult = await addTernary();
        setTernary(ternaryResult.data.addTernary.id);
        console.log(ternaryResult.data.addTernary);

    }
    
    useEffect(() => {
        if (introTwo) {
            handleSecondIntroUpdate();
        }
    }, [themeType, summary, basic, sentence, period, hybrid, ternary]);

  return (

    <>
    <Slide direction="up" in={intro} mountOnEnter unmountOnExit>
                <div>
                    <div className="fs-2 fw-light text-muted mt-5 pt-5">Introduction Two</div>
                    <hr className='pt-0 pb-0' />
                </div>
            </Slide>
            <SectionEnable title={'Second Introduction'} show={intro} checked={secondIntro} handleChange={handleSecondIntro} />
             <ThemeType show={intro && secondIntro} checked={themeType} handleChange={handleThemeEnable} component={introTwo}
                handleBasic={handleBasic} basicId={basic}
                handleSentence={handleSentence} sentenceId={sentence}
                handlePeriod={handlePeriod} periodId={period}
                handleHybrid={handleHybrid} hybridId={hybrid}
                handleTernary={handleTernary} ternaryId={ternary} 
            />
            <Summary show={intro && secondIntro} />

    </>
  )
}

export default IntroductionTwo