import MovementIntro from '../components/MovementIntro';
import MovementMainTheme from '../components/MovementMainTheme';
import MovementTransition from './MovementTransition';
import MovementSbTheme from '../components/MovementSbTheme';
import { useState } from 'react';

function Movements({ movements, activeMovement}) {
    return (
        <>
            {
                movements.map((movement) => {
                    return (
                        <div key={movement.id} hidden={movement.id != activeMovement.id}>
                            <MovementIntro movement={movement}/>
                            <MovementMainTheme movement={movement}/>
                            <MovementTransition movement={movement}/>
                            <MovementSbTheme movement={movement}/>
                        </div>
                    )
                })
            }
        </>

        /*<>
             <MovementIntro />
             <MovementMainTheme />
             <MovementTransition />
             <MovementSbTheme />
         </>*/
    )
}

export default Movements